import jwt_decode from 'jwt-decode';

const TOKEN_KEY = 'dsekon_token'; // Замените на желаемое имя

export function setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
    return localStorage.getItem(TOKEN_KEY);
}

export function removeToken() {
    localStorage.removeItem(TOKEN_KEY);
}

export function isAuthenticated() {
    const token = getToken();

    return !!token;
}

export function getUserInfo() {
    const token = getToken();

    if (token) {
        return jwt_decode(token, { header: true });
    }

    return null;
}

export async function login(token) {
    setToken(token);

    return token;
}

export function logout() {
    removeToken();
}
