import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import DBuilder from '&/DBuilder';

export default {
  __name: 'DBuilder',
  setup(__props) {

    
return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_unref(DBuilder)))
}
}

}