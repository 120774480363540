import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }

import {
        ref,
        watch
    } from 'vue';
    import DBtn from '@/Btn';
    import DInput from '@/Input';
    import DTextarea from '@/Textarea';

    
export default {
  __name: 'KBJU',
  setup(__props) {

    const json = ref('');
    const jsonResult = ref([]);
    const ingredients = ref('');
    const ingredientsDay = ref('');
    const ingredientsResult = ref([]);
    const sortEnter = ref([]);
    const sortResult = ref([]);
    const purchases = ref(null);
    const purchasesResult = ref(null);
    const purchasesAttention = ref(null);

    const year = ref(null);
    const month = ref(0);
    const week = ref(null);
    const day = ref(null);

    const tabs = ['Закупки', 'Перевод в месяцы', 'Общее КБЖУ', 'Ingredients', 'Sort']
    const tabActive = ref('Закупки');

    const distribution = ['овощи', 'грибы', 'фрукты', 'ягоды', 'орехи', 'сухофрукты', 'мясо и мясные изделия', 'рыба и морепродукты', 'колбасы и деликатесы', 'сыры', 'молочные продукты', 'хлеба и выпечка', 'замороженые продукты', 'напитки', 'кондитерские и хозяйственные товары', 'зерновые продукты, макаронные изделия и крупы', 'масла и соусы', 'консервы и продукты в банках', 'приправы и специи', 'здоровое питание и органические продукты'];
    const dataIngredients = ref(null);

    function handlerTab(value) {
        tabActive.value = value;
    }

    function convertToObjects(arr) {
        return arr.map(function(item) {
            const parts = item.split(" - ");

            return {
                name: parts[0],
                value: parts[1]
            };
        });
    }

    function parse(value) {
        return Number.parseFloat(!!value && value !== '' ? value : 0);
    }

    function calcKBJU(data, value) {
        const breakfast = data[`breakfast_${value}`];
        const snack1 = data[`snack1_${value}`];
        const lunch = data[`lunch_${value}`];
        const snack2 = data[`snack2_${value}`];
        const dinner = data[`dinner_${value}`];
        const snack3 = data[`snack3_${value}`];

        return Number.parseFloat((parse(breakfast) + parse(snack1) + parse(lunch) + parse(snack2) + parse(dinner) + parse(snack3)).toFixed(2)).toString();
    }

    function sortArrayByName(arr) {
        return arr.sort(function(a, b) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    watch(json, () => {
        const data = JSON.parse(json.value);

        data['day_k'] = calcKBJU(data, 'k');
        data['day_b'] = calcKBJU(data, 'b');
        data['day_j'] = calcKBJU(data, 'j');
        data['day_u'] = calcKBJU(data, 'u');

        jsonResult.value = JSON.stringify(data);

        navigator.clipboard.writeText(jsonResult.value);
    });

    watch(ingredients, () => {
        const mainData = JSON.parse(ingredients.value);

        ingredientsResult.value = [];

        for (let data of mainData) {
            for (let item of ['breakfast', 'snack1', 'lunch', 'snack2', 'dinner', 'snack3']) {
                const itemIngredients = data[`${item}_ingredients`];

                if (itemIngredients) {
                    ingredientsResult.value = ingredientsResult.value.concat(itemIngredients);
                }
            }
        }

        ingredientsResult.value = sortArrayByName(ingredientsResult.value);

        navigator.clipboard.writeText(JSON.stringify(ingredientsResult.value));
    });

    watch(sortEnter, () => {
        sortResult.value = sortArrayByName(JSON.parse(sortEnter.value));
    });

    watch(year, () => {
        if (year.value) {
            month.value = (+year.value / 12).toFixed(2);

            week.value = null;
            day.value = null;

            navigator.clipboard.writeText(month.value);
        }
    });

    watch(week, () => {
        if (week.value) {
            month.value = (+week.value / (30 / 7)).toFixed(2);

            year.value = null;
            day.value = null;

            navigator.clipboard.writeText(month.value);
        }
    });

    watch(day, () => {
        if (day.value) {
            month.value = (+day.value / 30).toFixed(2);

            year.value = null;
            week.value = null;

            navigator.clipboard.writeText(month.value);
        }
    });

    watch(purchases, () => {
        const purchasesObj = JSON.parse(purchases.value);
        const purchasesReturn = {};

        purchasesResult.value = [];
        purchasesAttention.value = null;

        for (const item of distribution) {
            purchasesReturn[item] = [];
        }

        purchasesReturn['other'] = [];

        for (let i = 0; i < purchasesObj.length; i++) {
            const item = purchasesObj[i];
            const ingredient = dataIngredients.value[item.name];

            if (ingredient) {
                if (ingredient.expiration_date <= 1) {
                    purchasesReturn[ingredient.category].push(item);
                }
            } else {
                purchasesReturn['other'].push(item);
            }
        }

        purchasesAttention.value = JSON.stringify(purchasesReturn['other']);

        for (const item of distribution) {
            purchasesResult.value = purchasesResult.value.concat(sortArrayByName(purchasesReturn[item]));
        }

        purchasesResult.value = JSON.stringify(purchasesResult.value);

        navigator.clipboard.writeText(purchasesResult.value);
    });

    fetch('/data/ingredients.json')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        })
        .then(data => {
            dataIngredients.value = data;
        })
        .catch(error => {
            console.error('Error loading JSON data:', error);
        });

return (_ctx, _cache) => {
  const _directive_ripple = _resolveDirective("ripple")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["container", _ctx.$style.container])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.tabs)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.tabs__list)
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tabs, (item) => {
            return _withDirectives(_createElementVNode("div", {
              key: item,
              class: _normalizeClass([_ctx.$style.tabs__item, {[_ctx.$style.active]: tabActive.value === item}]),
              onClick: $event => (handlerTab(item))
            }, [
              _createTextVNode(_toDisplayString(item), 1)
            ], 10, _hoisted_2), [
              [_directive_ripple, true]
            ])
          }), 64))
        ], 2)
      ], 2),
      (tabActive.value === 'Закупки')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['textarea-block'])
            }, [
              _createVNode(_unref(DTextarea), {
                modelValue: purchases.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((purchases).value = $event)),
                placeholder: "Закупки"
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(DTextarea), { value: purchasesResult.value }, null, 8, ["value"])
            ], 2),
            (purchasesAttention.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(purchasesAttention.value), 1))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (tabActive.value === 'Перевод в месяцы')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style['input-block'])
          }, [
            _createVNode(_unref(DInput), {
              modelValue: year.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((year).value = $event)),
              type: "number",
              placeholder: "Лет"
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(DInput), {
              modelValue: week.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((week).value = $event)),
              type: "number",
              placeholder: "Недель"
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(DInput), {
              modelValue: day.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((day).value = $event)),
              type: "number",
              placeholder: "Дней"
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(DInput), {
              modelValue: month.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((month).value = $event)),
              type: "number",
              placeholder: "Лет"
            }, null, 8, ["modelValue"])
          ], 2))
        : _createCommentVNode("", true),
      (tabActive.value === 'Общее КБЖУ')
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.$style['textarea-block'])
          }, [
            _createVNode(_unref(DTextarea), {
              modelValue: json.value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((json).value = $event)),
              placeholder: "Общее КБЖУ"
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(DTextarea), { value: jsonResult.value }, null, 8, ["value"])
          ], 2))
        : _createCommentVNode("", true),
      (tabActive.value === 'Ingredients')
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(_ctx.$style['textarea-block'])
          }, [
            _createVNode(_unref(DTextarea), {
              modelValue: ingredients.value,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((ingredients).value = $event)),
              placeholder: "Ingredients"
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(DTextarea), {
              value: `${JSON.stringify(ingredientsResult.value)}`
            }, null, 8, ["value"])
          ], 2))
        : _createCommentVNode("", true),
      (tabActive.value === 'Sort')
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: _normalizeClass(_ctx.$style['textarea-block'])
          }, [
            _createVNode(_unref(DTextarea), {
              modelValue: sortEnter.value,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((sortEnter).value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(DTextarea), {
              value: JSON.stringify(sortResult.value)
            }, null, 8, ["value"])
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

}