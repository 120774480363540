import { vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["onKeyup"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

import {
        ref,
        inject,
        onMounted,
        onUnmounted
    } from 'vue';
    import {
        useRoute
    } from 'vue-router';
    
export default {
  __name: 'Room',
  setup(__props) {

    const socket = inject('socket');
    const message = ref('');
    const messages = ref([]);
    const images = ref({});
    const lastMessage = ref('');

    const sendMessage = () => {
        socket.emit('message', {
            roomName: roomName,
            message: message.value
        });

        message.value = '';
    };

    const setImage = (image) => {
        socket.emit('setImage', {
            roomName: roomName,
            image: image
        });
    };

    const roomName = useRoute().params.roomName;

    onMounted(() => {
        socket.emit('enterRoom', {
            roomName: roomName
        });

        socket.on('getMsg', (msg) => {
            messages.value = msg.messages;
            images.value = msg.images;
        });
    });

    onUnmounted(() => {
        socket.emit('leaveRoom', {
            roomName: roomName
        });
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.test)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['input-wrap'])
    }, [
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(_ctx.$style.input),
        type: "text",
        placeholder: "Enter message",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((message).value = $event)),
        onKeyup: _withKeys(sendMessage, ["enter"])
      }, null, 42, _hoisted_1), [
        [_vModelText, message.value]
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['message-block'])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages.value, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.$style['message-block__item']),
          key: item
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['message-block__item-bg']),
            style: _normalizeStyle({'backgroundColor': item.color})
          }, null, 6),
          _createElementVNode("b", null, _toDisplayString(item.name), 1),
          _createTextVNode(" " + _toDisplayString(item.message.value) + " ", 1),
          (item.message.images.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['message-block__item-images'])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.message.images, (image) => {
                  return (_openBlock(), _createElementBlock("img", {
                    key: image,
                    src: 'data:image/jpeg;base64,' + images.value[image]
                  }, null, 8, _hoisted_2))
                }), 128))
              ], 2))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.img_block)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (image, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(_ctx.$style.img),
          onClick: $event => (setImage(index))
        }, [
          _createElementVNode("img", {
            src: 'data:image/jpeg;base64,' + image
          }, null, 8, _hoisted_4)
        ], 10, _hoisted_3))
      }), 128))
    ], 2)
  ], 2))
}
}

}