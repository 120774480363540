const DRipple = (() => {
    function createRipple($this) {
        const rippleEl = document.createElement('div');
        rippleEl.className = 'ripple';
        rippleEl.innerHTML = '<span></span>';

        $this.appendChild(rippleEl);

        return rippleEl;
    }

    function show(evt) {
        if (evt.which !== 1) return true;

        const $this = this;
        const ripple = createRipple($this);
        const rippleChild = ripple.children[0];
        const {
            top,
            left,
            width,
            height
        } = $this.getBoundingClientRect();
        let {
            borderWidth
        } = getComputedStyle($this);
        const posX = evt.clientX - left;
        const posY = evt.clientY - top;
        const biggerSide = Math.max(width, height);
        const halfSize = biggerSide / 2;

        borderWidth = parseFloat(borderWidth);

        ripple.style.top = `-${borderWidth}px`;
        ripple.style.left = `-${borderWidth}px`;
        ripple.style.width = `calc(100% + ${borderWidth * 2}px)`;
        ripple.style.height = `calc(100% + ${borderWidth * 2}px)`;

        rippleChild.style.top = `${posY - halfSize}px`;
        rippleChild.style.left = `${posX - halfSize}px`;
        rippleChild.style.width = `${biggerSide + borderWidth * 2}px`;
        rippleChild.style.height = `${biggerSide + borderWidth * 2}px`;
        rippleChild.style.transform = 'scale(2.5)';
    }

    function hide() {
        const $this = this;

        if (!$this) return;

        let ripple = null;
        const ripples = $this.getElementsByClassName('ripple');

        if (ripples.length > 0) {
            ripple = ripples[ripples.length - 1]
        } else {
            return false;
        }

        const duration = parseFloat(getComputedStyle(ripple)['transition-duration'].split(',')[0]) * 1000;

        setTimeout(() => {
            ripple.style.opacity = 0;

            setTimeout(() => {
                try {
                    $this.removeChild(ripple);
                } catch (e) {
                    return false;
                }
                if (ripples.length < 1) $this.style.position = '';

            }, duration)

        }, 0);
    }

    return {
        show,
        hide
    }
})();

export default {
    mounted(el, binding) {
        el._isShow = !(binding.value === false || false);

        if (el._isShow) {
            el.addEventListener('mousedown', DRipple.show);

            el.addEventListener('mouseleave', DRipple.hide);
            el.addEventListener('mouseup', DRipple.hide);
            el.addEventListener('dragend', DRipple.hide);
        }
    },
    unmounted(el) {
        if (el._isShow) {
            el.removeEventListener('mousedown', DRipple.show);

            el.removeEventListener('mouseleave', DRipple.hide);
            el.removeEventListener('mouseup', DRipple.hide);
            el.removeEventListener('dragend', DRipple.hide);
        }

        delete el._isShow;
    }
}
