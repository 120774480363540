import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]

import {
        ref,
        inject,
        onMounted
    } from 'vue';
    import {
        useRoute,
        useRouter
    } from 'vue-router';
    import DBtn from '@/Btn';
    
export default {
  __name: 'Test2',
  setup(__props) {

    const socket = inject('socket');
    const roomName = ref('');
    const router = useRouter();

    const createRoom = () => {
        socket.emit('createRoom', {
            base64Images: base64Images.value
        });
    };

    const base64Images = ref({});

    const handleFileUpload = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();
                reader.onload = () => {
                    // Convert the binary data to a Base64 string
                    base64Images.value[i] = btoa(reader.result);
                };
                reader.readAsBinaryString(file);
            }
        }
    };

    onMounted(() => {
        socket.on('roomCreated', (createdRoomName) => {
            router.push(`/room/${createdRoomName}`);
        });
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      type: "file",
      onChange: handleFileUpload,
      multiple: ""
    }, null, 32),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(base64Images.value, (image, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("img", {
          src: 'data:image/jpeg;base64,' + image,
          alt: "Uploaded Image"
        }, null, 8, _hoisted_1)
      ]))
    }), 128)),
    _createVNode(_unref(DBtn), { onClick: createRoom }, {
      default: _withCtx(() => [
        _createTextVNode(" Create room ")
      ]),
      _: 1
    })
  ]))
}
}

}