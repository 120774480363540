import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createSlots as _createSlots, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = ["onSubmit"]
const _hoisted_4 = { class: "offset--items_m" }

import {
        ref,
        watch
    } from 'vue';
    import {
        useRouter
    } from 'vue-router';
    import DBtn from '@/Btn';
    import DInput from '@/Input';
    import DField from '^/Field';
    import {
        login,
        logout,
        isAuthenticated,
        getUserInfo
    } from '../utils/auth';


    
export default {
  __name: 'Auth',
  setup(__props) {

    const router = useRouter();
    const errorMessage = ref('');

    function onInput() {
        errorMessage.value = '';
    }

    function serializeForm(form) {
        const formData = new FormData(form);
        const params = new URLSearchParams();

        for (const pair of formData.entries()) {
            params.append(pair[0], pair[1]);
        }

        return params.toString();
    }

    function registration(e) {
        const formData = new FormData(e.target);

        fetch('https://demailbuild.dsekon.com/api/auth', {
                method: 'POST',
                body: JSON.stringify({
                    email: formData.get('email'),
                    password: formData.get('password')
                }),
                redirect: 'follow'
            })
            .then(response => response.json())
            .then(result => {
                if (result.status === 'error') {
                    errorMessage.value = result.errors[0].message;
                } else if (result.status === 'success') {
                    console.log(result.data.token);
                    login(result.data.token);

                    router.push('/profile');
                }
            })
            .catch(error => console.log('error', error));
    }

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", {
        class: _normalizeClass(_ctx.$style.title)
      }, "Authorization", 2),
      _createElementVNode("form", {
        onSubmit: _withModifiers(registration, ["prevent"]),
        class: _normalizeClass(_ctx.$style.form)
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(DField), {
            class: _normalizeClass(["width-1-1", {
                                'status-red': errorMessage.value
                            }])
          }, {
            label: _withCtx(() => [
              _createTextVNode(" Email ")
            ]),
            default: _withCtx(({ parent_class, uid }) => [
              _createVNode(_unref(DInput), {
                id: uid,
                "d-class": parent_class,
                onInput: onInput,
                type: "email",
                name: "email",
                required: ""
              }, null, 8, ["id", "d-class"])
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_unref(DField), {
            class: _normalizeClass(["width-1-1", {
                                'status-red': errorMessage.value
                            }])
          }, _createSlots({
            label: _withCtx(() => [
              _createTextVNode(" Password ")
            ]),
            default: _withCtx(({ parent_class, uid }) => [
              _createVNode(_unref(DInput), {
                id: uid,
                "d-class": parent_class,
                onInput: onInput,
                type: "password",
                name: "password",
                required: ""
              }, null, 8, ["id", "d-class"])
            ]),
            _: 2
          }, [
            (errorMessage.value)
              ? {
                  name: "under_text",
                  fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(errorMessage.value), 1)
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["class"]),
          _createVNode(_unref(DBtn), {
            class: "width-1-1",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Submit ")
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: '/registration',
            custom: ""
          }, {
            default: _withCtx(({ navigate, href }) => [
              _createVNode(_unref(DBtn), {
                settings: {style: 'link'},
                href: href,
                onClick: navigate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Registration ")
                ]),
                _: 2
              }, 1032, ["href", "onClick"])
            ]),
            _: 1
          })
        ])
      ], 42, _hoisted_3)
    ])
  ]))
}
}

}