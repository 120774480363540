import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

import {
        computed
    } from 'vue'
    import {
        propClass,
        childClass
    } from 'dsrc/mixins';

    import DTitle from '@/Title'

    
export default {
  __name: 'index',
  props: {
        dClass: {
            type: String,
            default: null
        },
        settings: {
            type: Object,
            default: {}
        },
        modelValue: [String, Number],
        modelModifiers: {
            default: () => ({})
        }
    },
  emits: ['update:modelValue'],
  setup(__props, { emit }) {

const props = __props;

    

    const defaultClass = 'd-textarea';
    const getClass = computed(() => {
        return [
            childClass(defaultClass, props.dClass),
            defaultClass
        ]
    });

    

    function updateValue(e) {
        let value = e.target.value

        if (props.modelModifiers.capitalize) {
            value = value.charAt(0).toUpperCase() + value.slice(1)
        }

        emit('update:modelValue', value)
    }

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("textarea", {
    class: _normalizeClass(_unref(getClass)),
    value: __props.modelValue,
    onInput: updateValue
  }, null, 42, _hoisted_1))
}
}

}