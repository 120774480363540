import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import DBtn from '@/Btn';
    import DIcon from '@/Icon';

export default {
  __name: 'index',
  setup(__props) {

    
return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(_ctx.$style['footer'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style['footer__container'], 'container'])
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.$style['footer__copyright'])
      }, [
        _createTextVNode(" Coded with by "),
        _createVNode(_unref(DBtn), {
          settings: {style: 'link-2'},
          href: "https://DSekon.com"
        }, {
          default: _withCtx(() => [
            _createTextVNode("DSekon")
          ]),
          _: 1
        })
      ], 2),
      _createVNode(_unref(DBtn), {
        class: "header__btns-item",
        settings: {style: 'icon'},
        href: "https://www.instagram.com/dsekonn",
        target: "_blank"
      }, {
        before: _withCtx(({ parent_class }) => [
          _createVNode(_unref(DIcon), {
            "d-class": parent_class,
            name: "insta"
          }, null, 8, ["d-class"])
        ]),
        _: 1
      })
    ], 2)
  ], 2))
}
}

}