import {
    createApp
} from 'vue';

import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/backdrop.css'
import 'tippy.js/animations/shift-away.css'

import './assets/scss/index.scss';
import App from './App';
import './registerServiceWorker';

import router from './router';
import store from './store';
import directiveRipple from './directive/ripple';
import './assets/scss/customs/color.scss'; import './assets/scss/customs/offset.scss'; import './assets/scss/customs/text-decoration.scss'; import './assets/scss/customs/width.scss';

window.addEventListener('DOMContentLoaded', function(e) {
    if (location.hash) {
        setTimeout(function() {
            const offset = document.getElementById(location.hash.replace('#', '')).offsetTop
            window.scroll(0, offset - (document.querySelector('.header').offsetHeight + 16));
        }, 0);
    }
});

const app = createApp(App);

app.config.globalProperties.$prefix = 'd-';

app.use(store)
    .use(router)
    .use(VueTippy, {
        directive: 'tippy',
        component: 'tippy',
        defaultProps: {
            animation: 'shift-away',
            duration: 200,
            placement: 'bottom',
            offset: [0, 8]
        }
    })
    .directive('ripple', directiveRipple)
    .mount('#app');
