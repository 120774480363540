import { createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "section" }

import {
        ref,
        watch
    } from 'vue';
    import DBtn from '@/Btn';
    import DInput from '@/Input';
    import DTextarea from '@/Textarea';
    import DField from '^/Field'

    
export default {
  __name: 'Recipes',
  setup(__props) {

    const data = [{
        "day_num": "1-2",
        "day_k": "1844.3",
        "day_b": "133.8",
        "day_j": "80.5",
        "day_u": "138.5",
        "breakfast_name": "Ленивые вареники из кукурузной муки",
        "breakfast_k": "457",
        "breakfast_b": "38.8",
        "breakfast_j": "14.8",
        "breakfast_u": "42.2",
        "breakfast_ingredients": [{
            "name": "Творог 5%",
            "value": "170 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Мука кукурузная",
            "value": "30 гр"
        }, {
            "name": "Мед",
            "value": "20 гр"
        }],
        "breakfast_cooking_method": ["Смешайте творог, яйцо и кукурузную муку, замесите тесто до однородной консистенции.", "Разделите тесто на небольшие кусочки и скатайте их в небольшие шарики.", "Прокипятите воду в кастрюле и добавьте немного соли.", "Берите по несколько шариков теста и аккуратно опускайте их в кипящую воду.", "Варите ленивые вареники около 7 минут или до готовности. Они должны всплыть на поверхность воды.", "С помощью шумовки аккуратно достаньте вареники из кастрюли и выложите на тарелку.", "Полейте ленивые вареники медом.", "Подавайте горячими. Приятного аппетита!"],
        "snack1_name": "Рулетики с омлетом и овощами в лаваше",
        "snack1_k": "383.3",
        "snack1_b": "21",
        "snack1_j": "15.5",
        "snack1_u": "37.5",
        "snack1_ingredients": [{
            "name": "Лаваш",
            "value": "70 гр"
        }, {
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Молоко 2,5%",
            "value": "30 мл"
        }, {
            "name": "Помидор",
            "value": "50 гр"
        }, {
            "name": "Салат",
            "value": "15 гр"
        }, {
            "name": "Масло подсолнечное",
            "value": "2 мл"
        }],
        "snack1_cooking_method": ["Разогрейте сковороду на среднем огне и смажьте ее подсолнечным маслом.", "В отдельной миске взбейте яйца с молоком и солью.", "Вылейте яичную смесь на разогретую сковороду и приготовьте омлет, периодически помешивая, до готовности.", "Нарежьте помидоры на кубики", "Расположите салатные листья на лаваше, затем выложите на них нарезанные помидоры и омлет.", "Сверните лавашу в рулет и разрежьте на половинки перед подачей. Готовые рулетики готовы к употреблению. Приятного аппетита!"],
        "lunch_name": "Рис с курицей и овощами",
        "lunch_k": "335",
        "lunch_b": "20",
        "lunch_j": "13.7",
        "lunch_u": "33.8",
        "lunch_ingredients": [{
            "name": "Куриное филе",
            "value": "70 гр"
        }, {
            "name": "Рис",
            "value": "35 гр"
        }, {
            "name": "Вода",
            "value": "100 мл"
        }, {
            "name": "Лук репчатый",
            "value": "20 гр"
        }, {
            "name": "Морковь",
            "value": "25 гр"
        }, {
            "name": "Горошек замороженный",
            "value": "25 гр"
        }, {
            "name": "Масло оливковое",
            "value": "12.5 гр"
        }, {
            "name": "Соль",
            "value": "по вкусу"
        }],
        "lunch_cooking_method": ["Промойте рис и сварите его в воде до готовности.", "Почистите и нарежьте лук и морковь кубиками.", "Нарежьте куриное филе на кусочки.", "Разогрейте оливковое масло в глубокой сковороде и обжарьте куриное филе до золотистого цвета.", "Выньте филе из сковороды и добавьте лук и морковь. Обжаривайте их до мягкости.", "Верните обжаренное филе в сковороду, добавьте горошек, посолите по вкусу и тушите все вместе в течение 3-5 минут.", "Добавьте сваренный рис в сковороду, хорошо перемешайте все ингредиенты и тушите под крышкой еще некоторое время. При необходимости можно добавить немного воды. Готовый рис с курицей и овощами готов к подаче. Приятного аппетита!"],
        "snack2_name": "",
        "snack2_k": "",
        "snack2_b": "",
        "snack2_j": "",
        "snack2_u": "",
        "snack2_ingredients": "",
        "snack2_cooking_method": [],
        "dinner_name": "Капуста тушеная с курицей",
        "dinner_k": "270",
        "dinner_b": "27",
        "dinner_j": "6.5",
        "dinner_u": "20",
        "dinner_ingredients": [{
            "name": "Капуста белокочанная",
            "value": "260 гр"
        }, {
            "name": "Болгарский перец",
            "value": "65 гр"
        }, {
            "name": "Лук репчатый",
            "value": "45 гр"
        }, {
            "name": "Масло оливковое",
            "value": "1 ложка"
        }, {
            "name": "Чеснок",
            "value": "1 зубчик"
        }, {
            "name": "Вода",
            "value": "100 мл"
        }, {
            "name": "Куриное филе",
            "value": "100 гр"
        }],
        "dinner_cooking_method": ["Все овощи порезать.", "В сковороду или кастрюлю добавить масло и притушить на нем лук и чеснок со специями (кориандр, красный перец). Добавить перец сладкий и филе, через 5 минут добавить капусту.", "Тушить до готовности. При желании можно добавить немного томата или помидоры.", "Если необходимо, прибавить немного жидкости для тушения в зависимости от сочности исходных продуктов.", "В конце добавить паприку и посыпать укропом. Приятного аппетита!"],
        "snack3_name": "Болгарский омлет",
        "snack3_k": "399",
        "snack3_b": "27",
        "snack3_j": "30",
        "snack3_u": "5",
        "snack3_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Болгарский перец",
            "value": "70 гр"
        }, {
            "name": "Брынза",
            "value": "60 гр"
        }, {
            "name": "Масло сливочное 72%",
            "value": "10 гр"
        }],
        "snack3_cooking_method": ["Взбейте яйца в миске. Добавьте нарезанный кубиками перец и брынзу. Посолите по вкусу.", "Разогрейте сливочное масло на сковороде.", "Вылейте смесь яиц, перца и брынзы на сковороду с раскаленным маслом.", "Жарьте омлет на среднем огне до готовности. Периодически помешивайте и переворачивайте, чтобы обеспечить равномерное приготовление со всех сторон.", "Готовый болгарский омлет можно подавать горячим. Приятного аппетита!"]
    }, {
        "day_num": "3-4",
        "day_k": "1743.75",
        "day_b": "152.6",
        "day_j": "73.5",
        "day_u": "114",
        "breakfast_name": "Яичница с овощами",
        "breakfast_k": "455",
        "breakfast_b": "26.2",
        "breakfast_j": "21",
        "breakfast_u": "39.6",
        "breakfast_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Сыр",
            "value": "30 гр"
        }, {
            "name": "Помидор",
            "value": "30 гр"
        }, {
            "name": "Хлеб цельнозерновой",
            "value": "40 гр (1 шт)"
        }, {
            "name": "Зефир",
            "value": "20 гр (половинка)"
        }],
        "breakfast_cooking_method": ["Разогрейте сковороду с каплей масла.", "Взбейте яйца в миске и вылейте на разогретую сковороду.", "Готовьте яичницу до желаемой степени готовности.", "Выложите яичницу на тарелку.", "Нарежьте помидор и сыр на ломтики.", "Подавайте яичницу с помидором, сыром и хлебом. Добавьте половинку зефира для десерта. Приятного аппетита!"],
        "snack1_name": "Диетическая шаурма",
        "snack1_k": "299.55",
        "snack1_b": "38.8",
        "snack1_j": "4.4",
        "snack1_u": "25.6",
        "snack1_ingredients": [{
            "name": "Лаваш",
            "value": "40 гр"
        }, {
            "name": "Куриное филе",
            "value": "40 гр"
        }, {
            "name": "Капуста белокочанная",
            "value": "50 гр"
        }, {
            "name": "Огурец соленый",
            "value": "70 гр"
        }, {
            "name": "Творог 2%",
            "value": "70 гр"
        }],
        "snack1_cooking_method": ["Нарежьте отварное филе и соленый огурец на мелкие кусочки.", "Нашинкуйте капусту.", "В миске смешайте нарезанное филе, огурец и капусту.", "Добавьте нежирный творог и хорошо перемешайте.", "Присолите начинку по вкусу.", "Разложите начинку на лаваш и аккуратно заверните.", "Поджарьте лаваш на сухой сковороде до легкой румяной корочки.", "Готовую диетическую шаурму можно нарезать на порционные кусочки и подавать. Приятного аппетита!"],
        "lunch_name": "Гречка с фрикадельками из говядины",
        "lunch_k": "501.6",
        "lunch_b": "33.5",
        "lunch_j": "23.5",
        "lunch_u": "38.6",
        "lunch_ingredients": [{
            "name": "Фарш из говядины",
            "value": "100 гр"
        }, {
            "name": "Болгарский перец",
            "value": "25 гр"
        }, {
            "name": "Лук репчатый",
            "value": "15 гр"
        }, {
            "name": "Сыр голландский",
            "value": "25 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Соль",
            "value": "по вкусу"
        }, {
            "name": "Гречка",
            "value": "40 гр"
        }, {
            "name": "Огурец",
            "value": "200 гр"
        }],
        "lunch_cooking_method": ["Почистите перец и лук, затем измельчите их в блендере или нарежьте мелкими кубиками.", "В большой миске соедините фарш из говядины, нарезанный перец, лук и яйцо. Посолите по вкусу и хорошо вымешайте.", "Разрежьте сыр на небольшие кубики.", "Сформируйте небольшие фрикадельки из фарша, положите внутрь каждой фрикадельки по кусочку сыра.", "Обжарьте фрикадельки на масле на среднем огне до золотистой корочки, примерно по 3 минуты с каждой стороны. Вы можете также запечь фрикадельки в разогретой духовке или аэрогриле на противне, смазанном маслом, при 220 градусах в течение 10 минут.", "Отварите гречку до готовности в подсоленной воде.", "Подавайте фрикадельки с гречкой и нарезанным огурцом. Приятного аппетита!"],
        "snack2_name": "",
        "snack2_k": "",
        "snack2_b": "",
        "snack2_j": "",
        "snack2_u": "",
        "snack2_ingredients": "",
        "snack2_cooking_method": [],
        "dinner_name": "Запеченное куриное филе с салатом из капусты и огурца",
        "dinner_k": "269.2",
        "dinner_b": "36.1",
        "dinner_j": "10.5",
        "dinner_u": "4.4",
        "dinner_ingredients": [{
            "name": "Капуста белокочанная",
            "value": "50 гр"
        }, {
            "name": "Огурец",
            "value": "35 гр"
        }, {
            "name": "Лук репчатый",
            "value": "15 гр"
        }, {
            "name": "Соль",
            "value": "½ ч.л."
        }, {
            "name": "Масло оливковое",
            "value": "1 ст.л."
        }, {
            "name": "Куриное филе",
            "value": "150 гр"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "dinner_cooking_method": ["Нарежьте белокочаную капусту , огурец и репчатый лук.", "Добавьте соль и оливковое масло.", "Для запеченного куриного филе: Разогрейте духовку до 200 градусов Цельсия.", "Куриное филе смажьте оливковым маслом и посолите, приправьте специями по своему вкусу.", "Выложите куриное филе на противень, покрытый пергаментной бумагой и Запекайте куриное филе в духовке около 20-25 минут или до готовности. Время запекания может варьироваться. Приятного аппетита!"],
        "snack3_name": "Отварные яйца с огурцом",
        "snack3_k": "218.4",
        "snack3_b": "18",
        "snack3_j": "14.1",
        "snack3_u": "5.8",
        "snack3_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Огурец",
            "value": "200 гр"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "5-6",
        "day_k": "1656.5",
        "day_b": "160.9",
        "day_j": "53.1",
        "day_u": "127.5",
        "breakfast_name": "Ленивая ПП-шаурма",
        "breakfast_k": "448",
        "breakfast_b": "38.8",
        "breakfast_j": "14.9",
        "breakfast_u": "39",
        "breakfast_ingredients": [{
            "name": "Лаваш",
            "value": "70 гр"
        }, {
            "name": "Куриное филе",
            "value": "100 гр"
        }, {
            "name": "Авокадо",
            "value": "30 гр"
        }, {
            "name": "Огурец",
            "value": "50 гр"
        }, {
            "name": "Сыр сливочный мягкий",
            "value": "20 гр"
        }],
        "breakfast_cooking_method": ["Нарежьте отварное куриное филе, огурец и авокадо на полосочки.", "Смажьте лаваш сливочным сыром.", "Выложите на лаваш куриное филе, огурец и авокадо.", "Закрутите лаваш, чтобы получился рулет. Приятного аппетита!"],
        "snack1_name": "Апельсин и кешью",
        "snack1_k": "274.9",
        "snack1_b": "5.4",
        "snack1_j": "18.7",
        "snack1_u": "19.8",
        "snack1_ingredients": [{
            "name": "Апельсин",
            "value": "200 гр"
        }, {
            "name": "Кешью",
            "value": "30 гр"
        }],
        "snack1_cooking_method": [],
        "lunch_name": "Чечевица с курицей и овощами",
        "lunch_k": "261",
        "lunch_b": "31",
        "lunch_j": "4.5",
        "lunch_u": "22.3",
        "lunch_ingredients": [{
            "name": "Чечевица",
            "value": "25 гр"
        }, {
            "name": "Чеснок",
            "value": "1 зубчик"
        }, {
            "name": "Помидор",
            "value": "75 гр"
        }, {
            "name": "Болгарский перец",
            "value": "50 гр"
        }, {
            "name": "Кабачок",
            "value": "50 гр"
        }, {
            "name": "Лук репчатый",
            "value": "25 гр"
        }, {
            "name": "Масло оливковое",
            "value": "1 ч.л."
        }, {
            "name": "Куриное филе",
            "value": "100 гр"
        }],
        "lunch_cooking_method": ["В кастрюле отварите чечевицу в подсоленной воде до готовности.", "На сковороде разогрейте оливковое масло. Добавьте нарезанный лук, чеснок, кабачок и перец сладкий. Обжарьте овощи до мягкости.", "Добавьте нарезанное куриное филе на сковороду и готовьте до полной прожарки и приготовления курицы.", "Добавьте отваренную чечевицу к овощам и курице.", "Посолите и поперчите по вкусу. Приятного аппетита!"],
        "snack2_name": "Омлет с овощами и фаршем",
        "snack2_k": "295.6",
        "snack2_b": "35.8",
        "snack2_j": "8",
        "snack2_u": "18.9",
        "snack2_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Куриное филе",
            "value": "60 гр"
        }, {
            "name": "Лук репчатый",
            "value": "50 гр"
        }, {
            "name": "Помидор",
            "value": "100 гр"
        }, {
            "name": "Горошек зеленый",
            "value": "50 гр"
        }, {
            "name": "Молоко",
            "value": "50 мл"
        }],
        "snack2_cooking_method": ["В миске смешайте яйца с молоком. Добавьте небольшое количество соли и мелко резанного укропа.", "На сковороду выложите куриный фарш, накрошенный лук, помидоры и зеленый горошек (можно использовать замороженный). Тушите ингредиенты до готовности фарша.", "После тушения фарша, залейте его приготовленной яично-молочной смесью.", "Готовьте омлет на среднем огне, периодически помешивая, пока яичная смесь не закрепится и омлет не приобретет золотистый цвет.", "Готовый омлет с овощами и фаршем можно подавать горячим. Приятного аппетита!"],
        "dinner_name": "Запеченная курица с овощами",
        "dinner_k": "166",
        "dinner_b": "25.3",
        "dinner_j": "5.5",
        "dinner_u": "2.5",
        "dinner_ingredients": [{
            "name": "Куриное филе",
            "value": "75 гр"
        }, {
            "name": "Кабачок",
            "value": "40 гр"
        }, {
            "name": "Сыр твердый",
            "value": "25 гр"
        }, {
            "name": "Йогурт 1.5%",
            "value": "20 гр"
        }],
        "dinner_cooking_method": ["Предварительно разогрейте духовку до 180 градусов.", "Нарежьте куриное филе и кабачок на кубики. Посолите и поперчите по вкусу.", "Положите курицу и кабачки в миску и залейте йогуртом. Перемешайте, чтобы все ингредиенты были покрыты йогуртом.", "Оставьте мариноваться в холодильнике на 30 минут.", "Выложите маринованные ингредиенты на противень, посыпьте тертым сыром.", "Поставьте противень в разогретую духовку и готовьте около 30 минут, пока курица не будет прожарена и кабачки не станут мягкими и золотистыми.", "Подавайте блюдо горячим. Приятного аппетита!"],
        "snack3_name": "Творог с медом",
        "snack3_k": "211",
        "snack3_b": "24.6",
        "snack3_j": "1.5",
        "snack3_u": "25",
        "snack3_ingredients": [{
            "name": "Творог 1%",
            "value": "150 гр"
        }, {
            "name": "Мед",
            "value": "30 гр"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "7-8",
        "day_k": "1751.6",
        "day_b": "135",
        "day_j": "56",
        "day_u": "129.4",
        "breakfast_name": "Омлет с овощами и сыром",
        "breakfast_k": "433.2",
        "breakfast_b": "29.4",
        "breakfast_j": "15.7",
        "breakfast_u": "29.3",
        "breakfast_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Помидор",
            "value": "160 гр"
        }, {
            "name": "Спаржевая фасоль",
            "value": "100 гр"
        }, {
            "name": "Хлеб цельнозерновой",
            "value": "1 ломтик (40 гр)"
        }, {
            "name": "Сыр твердый",
            "value": "30 гр"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "breakfast_cooking_method": ["В миске взбейте яйца вилкой до однородной консистенции.", "Помидоры помойте и нарежьте кубиками. Фасоль стручковую нарежьте на небольшие кусочки.", "В сковороде разогрейте немного масла.", "Вылейте взбитые яйца в сковороду и равномерно распределите по дну.", "Посыпьте сверху нарезанными помидорами и фасолью.", "Посолите, добавьте специи по вкусу.", "Готовьте омлет на среднем огне до готовности яиц, примерно 3-5 минут.", "Осторожно переверните омлет на другую сторону и прожарьте ещё 1-2 минуты.", "Подавайте омлет с помидорками с цельнозерновым хлебом и посыпьте тертым твердым сыром. Приятного аппетита!"],
        "snack1_name": "Творог с ягодами и зефиром",
        "snack1_k": "236",
        "snack1_b": "18",
        "snack1_j": "2.1",
        "snack1_u": "31.8",
        "snack1_ingredients": [{
            "name": "Зефир",
            "value": "20 гр (половина 1 шт)"
        }, {
            "name": "Ягоды",
            "value": "200 гр"
        }, {
            "name": "Творог 1%",
            "value": "100 гр"
        }],
        "snack1_cooking_method": [],
        "lunch_name": "Плов с куриной грудкой",
        "lunch_k": "504",
        "lunch_b": "39.9",
        "lunch_j": "9.6",
        "lunch_u": "33.3",
        "lunch_ingredients": [{
            "name": "Рис",
            "value": "40 гр"
        }, {
            "name": "Куриная грудка",
            "value": "100 гр"
        }, {
            "name": "Лук репчатый",
            "value": "25 гр"
        }, {
            "name": "Морковь",
            "value": "35 гр"
        }, {
            "name": "Вода",
            "value": "200 мл"
        }, {
            "name": "Масло подсолнечное",
            "value": "8 гр"
        }],
        "lunch_cooking_method": ["Куриную грудку и лук нарежьте,морковь натрите на крупной терке.", "В сковороде разогрейте масло.", "Добавьте нарезанный лук и обжарьте до золотистого цвета.", "Добавьте нарезанную курицу.", "Добавьте натертую морковь и продолжайте готовить.", "Добавьте рис в сковороду и хорошо перемешайте.", "Добавьте воду, посолите по вкусу и доведите до кипения.", "Уменьшите огонь, накройте сковороду крышкой и варите плов на низком огне около 15-20 минут или до готовности риса. Приятного аппетита!"],
        "snack2_name": "Овощной салат",
        "snack2_k": "190",
        "snack2_b": "4.6",
        "snack2_j": "12.3",
        "snack2_u": "17.2",
        "snack2_ingredients": [{
            "name": "Огурец",
            "value": "150 гр"
        }, {
            "name": "Болгарский перец",
            "value": "150 гр"
        }, {
            "name": "Помидор",
            "value": "180 гр"
        }, {
            "name": "Масло оливковое",
            "value": "1 ч.л."
        }],
        "snack2_cooking_method": ["Нарежьте огурцы, перец и помидоры на кусочки.", "Полейте овощи оливковым маслом и хорошо перемешайте, чтобы все ингредиенты равномерно смешались.", "Овощной салат готов! Подавайте его как закуску или гарнир к основному блюду. Приятного аппетита!"],
        "dinner_name": "Тушеная куриная грудка с кабачками",
        "dinner_k": "170",
        "dinner_b": "25.1",
        "dinner_j": "2.2",
        "dinner_u": "12",
        "dinner_ingredients": [{
            "name": "Куриная грудка",
            "value": "100 гр"
        }, {
            "name": "Кабачок",
            "value": "250 гр"
        }, {
            "name": "Вода",
            "value": "100 мл"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "dinner_cooking_method": ["Курицу нарежьте небольшими кусочками.", "Кабачки вымойте и нарежьте тонкими полукольцами.", "В глубокой сковороде разогрейте немного масла.", "Добавьте нарезанную курицу и обжарьте до золотистого цвета.", "Добавьте нарезанные кабачки и продолжайте тушить на среднем огне около 10 минут до мягкости.", "Посолите, добавьте специи по вкусу и перемешайте.", "Добавьте воду, уменьшите огонь и тушите кабачки с курицей ещё около 5-7 минут, пока овощи станут мягкими и сочными. Приятного аппетита!"],
        "snack3_name": "Отварные яйца с огурцом",
        "snack3_k": "218.4",
        "snack3_b": "18",
        "snack3_j": "14.1",
        "snack3_u": "5.8",
        "snack3_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Огурец",
            "value": "200 гр"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "9-10",
        "day_k": "1668.1",
        "day_b": "150.64",
        "day_j": "74",
        "day_u": "116.1",
        "breakfast_name": "Творожно-белковая запеканка на овсяной муке",
        "breakfast_k": "294",
        "breakfast_b": "26.3",
        "breakfast_j": "7.8",
        "breakfast_u": "29.7",
        "breakfast_ingredients": [{
            "name": "Творог 2%",
            "value": "100 гр"
        }, {
            "name": "Яичный белок",
            "value": "1 шт"
        }, {
            "name": "Сахар",
            "value": "1 ст.л"
        }, {
            "name": "Овсяные хлопья",
            "value": "30 гр"
        }, {
            "name": "Сода",
            "value": "2 гр"
        }, {
            "name": "Масло подсолнечное",
            "value": "3 гр"
        }, {
            "name": "Ряженка 2.5%",
            "value": "45 гр"
        }],
        "breakfast_cooking_method": ["Перемолоть овсянку в кофемолке.", "В большой миске смешайте овсянку, творог, яичный белок, сахар и остальные ингредиенты, включая 0,3 гр ванилина.", "Дайте тесту отдохнуть на 15 минут, чтобы овсяные хлопья набухли.", "Запекать в микроволновке или духовке до готовности.", "Подавайте готовую овсяную запеканку на твороге с ряженкой. Приятного аппетита!"],
        "snack1_name": "Творог с ягодами и йогуртом",
        "snack1_k": "168.5",
        "snack1_b": "12.4",
        "snack1_j": "3.2",
        "snack1_u": "24.1",
        "snack1_ingredients": [{
            "name": "Ягоды",
            "value": "200 гр"
        }, {
            "name": "Творог 1%",
            "value": "50 гр"
        }, {
            "name": "Йогурт Активиа без сахара",
            "value": "75 гр"
        }],
        "snack1_cooking_method": [],
        "lunch_name": "Гречка с куриными котлетами",
        "lunch_k": "267.2",
        "lunch_b": "25.94",
        "lunch_j": "2.8",
        "lunch_u": "34.6",
        "lunch_ingredients": [{
            "name": "Куриная грудка",
            "value": "80 гр"
        }, {
            "name": "Морковь",
            "value": "20 гр"
        }, {
            "name": "Кабачок",
            "value": "50 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Мука цельнозерновая",
            "value": "1 ст.л"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }, {
            "name": "Гречка",
            "value": "40 гр"
        }],
        "lunch_cooking_method": ["Промойте гречку, залейте водой в пропорции 1:2 и варите под крышкой около 15-20 минут.", "Очистите и натрите на крупной терке морковь и кабачок.", "Прокрутите куриную грудку через мясорубку или мелко нарежьте.", "В миску с курицей и овощами добавьте муку, яйцо, соль и специи по вкусу. Перемешайте до однородного состояния.", "Разогрейте сковороду на среднем огне и добавьте немного масла. Когда масло будет горячим, выложите на сковородку большими ложками получившуюся смесь и обжаривайте котлеты с каждой стороны до золотистого цвета, это займет примерно 3-4 минуты с каждой стороны.", "Подавайте котлетки с гречкой. Приятного аппетита!"],
        "snack2_name": "Кешью и отварные яйца",
        "snack2_k": "330.4",
        "snack2_b": "22.8",
        "snack2_j": "33.3",
        "snack2_u": "9.8",
        "snack2_ingredients": [{
            "name": "Кешью",
            "value": "40 гр"
        }, {
            "name": "Яйцо",
            "value": "2 шт"
        }],
        "snack2_cooking_method": [],
        "dinner_name": "Ленивая курогрудка с овощным салатом",
        "dinner_k": "308",
        "dinner_b": "54",
        "dinner_j": "2.8",
        "dinner_u": "6.7",
        "dinner_ingredients": [{
            "name": "Куриная грудка",
            "value": "225 гр"
        }, {
            "name": "Карри",
            "value": "2 гр"
        }, {
            "name": "Прованские травы (или любые любимые специи)"
        }, {
            "name": "Огурец",
            "value": "200 гр"
        }, {
            "name": "Помидор",
            "value": "100 гр"
        }, {
            "name": "Масло оливковое",
            "value": "1 ч.л."
        }, {
            "name": "Соль",
            "value": "по вкусу"
        }],
        "dinner_cooking_method": ["Натрите куриную грудку специями по вкусу, включая карри и прованские травы.", "Выложите куриную грудку на противень и запекайте в предварительно разогретой духовке на 180 градусов Цельсия в течение 20-25 минут, пока мясо не станет сочным и прожаренным.", "Нарежьте огурцы и помидоры.", "В миске смешайте нарезанные огурцы и помидоры с оливковым маслом.", "Приправьте овощной салат солью по вкусу.", "Подавайте запеченную куриную грудку с овощным салатом. Приятного аппетита!"],
        "snack3_name": "Кешью",
        "snack3_k": "300",
        "snack3_b": "9.2",
        "snack3_j": "24.1",
        "snack3_u": "11.2",
        "snack3_ingredients": [{
            "name": "Кешью",
            "value": "50 гр"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "11-12",
        "day_k": "1672.9",
        "day_b": "121.1",
        "day_j": "60.1",
        "day_u": "159.9",
        "breakfast_name": "Каша пшенная с яйцом",
        "breakfast_k": "431",
        "breakfast_b": "21.6",
        "breakfast_j": "19.4",
        "breakfast_u": "42.8",
        "breakfast_ingredients": [{
            "name": "Пшено",
            "value": "50 гр"
        }, {
            "name": "Молоко",
            "value": "100 мл"
        }, {
            "name": "Вода",
            "value": "30 гр"
        }, {
            "name": "Масло сливочное 72%",
            "value": "5 гр"
        }, {
            "name": "Сахар",
            "value": "1 ч.л."
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Соль",
            "value": "щепотка"
        }],
        "breakfast_cooking_method": ["В кастрюле добавьте молоко и воду, посолите по вкусу, доведите до кипения.", "Помойте пшено под проточной водой и добавьте в кастрюлю с кипящей жидкостью.", "Варите пшенную кашу на медленном огне, помешивая время от времени, примерно 30 минут или до готовности пшена. При необходимости добавьте еще немного воды, если каша станет слишком густой.", "Когда пшено готово, добавьте сливочное масло и сахар, хорошо перемешайте.", "Подавайте кашу горячей, украсив отварным яйцом сверху. Приятного аппетита!"],
        "snack1_name": "Творог с ягодами, кешью и сметаной",
        "snack1_k": "418.9",
        "snack1_b": "26.5",
        "snack1_j": "23.1",
        "snack1_u": "31.5",
        "snack1_ingredients": [{
            "name": "Кешью",
            "value": "50 гр"
        }, {
            "name": "Творог 1%",
            "value": "100 гр"
        }, {
            "name": "Ягоды",
            "value": "200 гр"
        }, {
            "name": "Сметана 10%",
            "value": "1 ст.л"
        }],
        "snack1_cooking_method": [],
        "lunch_name": "Запеченая картошка с курицей и овощами",
        "lunch_k": "350",
        "lunch_b": "29.5",
        "lunch_j": "2.6",
        "lunch_u": "49.4",
        "lunch_ingredients": [{
            "name": "Картошка",
            "value": "200 гр"
        }, {
            "name": "Куриное филе",
            "value": "100 гр"
        }, {
            "name": "Огурец",
            "value": "200 гр"
        }, {
            "name": "Капуста белокочанная",
            "value": "200 гр"
        }, {
            "name": "Сметана 10%",
            "value": "1 ст.л"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "lunch_cooking_method": ["Предварительно разогрейте духовку до 200 градусов Цельсия.", "Картошку и филе курицы нарежьте небольшими кусочками.", "Заправьте картошку и курицу специями по вкусу и перемешайте.", "Разложите картошку и курицу на противень, покрытый пергаментной бумагой.", "Запекайте в предварительно разогретой духовке около 30-35 минут, пока картошка и курица не станут золотистыми и хрустящими.", "Огурец нарежьте кружочками, а капусту белокочанную нарежьте тонкой соломкой.", "В миске смешайте огурец и капусту, добавьте сметану, соль и специи по вкусу. Хорошо перемешайте.", "Подавайте печеную картошку с курицей вместе с овощным салатом. Приятного аппетита!"],
        "snack2_name": "",
        "snack2_k": "",
        "snack2_b": "",
        "snack2_j": "",
        "snack2_u": "",
        "snack2_ingredients": "",
        "snack2_cooking_method": [],
        "dinner_name": "Котлеты куриные с творогом и огурцом",
        "dinner_k": "298",
        "dinner_b": "36",
        "dinner_j": "10",
        "dinner_u": "10.2",
        "dinner_ingredients": [{
            "name": "Фарш куриный",
            "value": "150 гр"
        }, {
            "name": "Лук репчатый",
            "value": "20 гр"
        }, {
            "name": "Чеснок",
            "value": "1 зубчик"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Творог 1%",
            "value": "20 гр"
        }, {
            "name": "Огурец",
            "value": "300 гр"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "dinner_cooking_method": ["Подготовьте куриный фарш, измельчив лук и чеснок.", "В большой миске смешайте фарш, лук, чеснок, яйцо, творог, соль и специи. Хорошо перемешайте, чтобы все ингредиенты хорошо соединились.", "Сформируйте котлеты из полученной смеси и выложите их на противень, предварительно покрытый пергаментной бумагой.", "Выпекайте котлеты в предварительно разогретой до 200 градусов печи в течение 40 минут, или до того, как они станут золотистыми и полностью прожаренными внутри.", "Огурец нарежьте тонкими кружками.", "Подавайте котлеты с творогом вместе с огурцом. По желанию можно добавить соус или гарнир. Приятного аппетита!"],
        "snack3_name": "Питьевой йогурт активиа без сахара",
        "snack3_k": "175",
        "snack3_b": "7.5",
        "snack3_j": "5",
        "snack3_u": "26",
        "snack3_ingredients": {
            "name": "Йогурт Активиа без сахара",
            "value": "260 гр"
        },
        "snack3_cooking_method": []
    }, {
        "day_num": "13-14",
        "day_k": "1449.4",
        "day_b": "136.8",
        "day_j": "49.9",
        "day_u": "103.3",
        "breakfast_name": "Ленивая пицца на лаваше",
        "breakfast_k": "380",
        "breakfast_b": "23",
        "breakfast_j": "5",
        "breakfast_u": "52",
        "breakfast_ingredients": [{
            "name": "Лаваш",
            "value": "75 гр"
        }, {
            "name": "Йогурт натуральный",
            "value": "50 гр"
        }, {
            "name": "Томатная паста",
            "value": "15 гр"
        }, {
            "name": "Сыр 30%",
            "value": "30 гр"
        }, {
            "name": "Ветчина/курица",
            "value": "50 гр"
        }, {
            "name": "Помидор",
            "value": "50 гр"
        }, {
            "name": "Зелень",
            "value": "по вкусу"
        }],
        "breakfast_cooking_method": ["Разместите лаваш на сковороде.", "Смажьте лаваш йогуртом и томатной пастой.", "Выложите на лаваш ветчину или курицу, нарезанные помидоры и посыпьте сыром.", "Посыпьте зеленью по вкусу.", "Жарьте под крышкой на среднем огне около 5 минут или до того, как сыр растает. Приятного аппетита!"],
        "snack1_name": "Тунец в собственном соку с овощами",
        "snack1_k": "259",
        "snack1_b": "49",
        "snack1_j": "4",
        "snack1_u": "9",
        "snack1_ingredients": [{
            "name": "Тунец в собственном соку",
            "value": "200 гр"
        }, {
            "name": "Огурец",
            "value": "100 гр"
        }, {
            "name": "Помидор",
            "value": "100 гр"
        }, {
            "name": "Салат/руккола",
            "value": "150 гр"
        }],
        "snack1_cooking_method": ["Нарежьте огурцы и помидоры на кусочки.", "Разложите огурцы, помидоры и салат или рукколу на тарелку.", "Добавьте тунец в собственном соку на верхушку салата.", "Подавайте и наслаждайтесь. Приятного аппетита!"],
        "lunch_name": "Паста с шампиньонами в сливочном соусе",
        "lunch_k": "393",
        "lunch_b": "19",
        "lunch_j": "17",
        "lunch_u": "39",
        "lunch_ingredients": [{
            "name": "Макароны из ТСП",
            "value": "50 гр"
        }, {
            "name": "Шампиньоны",
            "value": "125 гр"
        }, {
            "name": "Сливки 10%",
            "value": "100 гр"
        }, {
            "name": "Сыр 30%",
            "value": "15 гр"
        }, {
            "name": "Масло растительное",
            "value": "1 ч.л"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "lunch_cooking_method": ["Отварите макароны по инструкции на упаковке.", "На разогретой сковороде добавьте каплю растительного масла и обжарьте шампиньоны до золотистого цвета.", "Посолите и добавьте специи по вкусу к шампиньонам.", "Добавьте сливки в сковороду с шампиньонами и тушите на среднем огне до загустения соуса.", "Добавьте отварные макароны в сковороду с соусом и тщательно перемешайте, чтобы макароны равномерно покрылись соусом.", "Посыпьте сверху натертым сыром.", "Подавайте пасту с шампиньонами в сливочном соусе горячей. Приятного аппетита!"],
        "snack2_name": "",
        "snack2_k": "",
        "snack2_b": "",
        "snack2_j": "",
        "snack2_u": "",
        "snack2_ingredients": "",
        "snack2_cooking_method": [],
        "dinner_name": "Белковый блинчик с томатом, сыром и курицей",
        "dinner_k": "227",
        "dinner_b": "30.4",
        "dinner_j": "10",
        "dinner_u": "2.5",
        "dinner_ingredients": [{
            "name": "Куриное филе",
            "value": "75 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Помидор",
            "value": "50 гр"
        }, {
            "name": "Сыр 30%",
            "value": "15 гр"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "dinner_cooking_method": ["В блендере смешайте куриное филе и яйцо до получения однородного теста.", "Разогрейте сковороду на среднем огне и вылейте тесто на сковороду. Разровняйте тесто, чтобы получился равномерный блинчик.", "Через 2 минуты переверните блинчик и сверху выложите нарезанный помидор и посыпьте сыром.", "Продолжайте готовить блинчик до готовности. Убедитесь, что куриное филе полностью прожарено.", "Подавайте белковый блинчик горячим. Приятного аппетита!"],
        "snack3_name": "Отварные яйца",
        "snack3_k": "190.4",
        "snack3_b": "15.4",
        "snack3_j": "13.9",
        "snack3_u": "0.8",
        "snack3_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "15-16",
        "day_k": "1632.5",
        "day_b": "91.9",
        "day_j": "60.7",
        "day_u": "141.7",
        "breakfast_name": "Сырники с рисовой мукой и изюмом",
        "breakfast_k": "290",
        "breakfast_b": "31.4",
        "breakfast_j": "2.7",
        "breakfast_u": "35.2",
        "breakfast_ingredients": [{
            "name": "Творог",
            "value": "120 гр"
        }, {
            "name": "Мука рисовая",
            "value": "30 гр"
        }, {
            "name": "Яичный белок",
            "value": "2 шт"
        }, {
            "name": "Изюм",
            "value": "10 гр"
        }, {
            "name": "Подсластитель и ваниль",
            "value": "по вкусу"
        }],
        "breakfast_cooking_method": ["Растереть в тарелке творог, добавить белки и подсластитель с ванилином, перемешать.", "Просеять рисовую муку, положить запаренный изюм в общую массу и хорошо вымешать еще раз.", "Разделить тесто на части и сформировать небольшие сырники.", "Обжарить сырники на антипригарной сковороде с обеих сторон до золотистого цвета.", "Готовые сырники можно подавать с йогуртом, медом или свежими ягодами по желанию. Приятного аппетита!"],
        "snack1_name": "Банан и зефир",
        "snack1_k": "329.1",
        "snack1_b": "2.6",
        "snack1_j": "0.7",
        "snack1_u": "51.1",
        "snack1_ingredients": [{
            "name": "Банан",
            "value": "150 гр"
        }, {
            "name": "Зефир",
            "value": "60 гр"
        }],
        "snack1_cooking_method": [],
        "lunch_name": "Гречка с курицей и овощами",
        "lunch_k": "251.4",
        "lunch_b": "17",
        "lunch_j": "7.6",
        "lunch_u": "19.6",
        "lunch_ingredients": [{
            "name": "Гречка",
            "value": "40 гр"
        }, {
            "name": "Шампиньоны",
            "value": "100 гр"
        }, {
            "name": "Куриное филе",
            "value": "30 гр"
        }, {
            "name": "Лук репчатый",
            "value": "25 гр"
        }, {
            "name": "Масло сливочное",
            "value": "6 гр"
        }, {
            "name": "Морковь",
            "value": "30 гр"
        }],
        "lunch_cooking_method": ["На разогретой сковороде добавьте небольшое количество сливочного масла и обжарьте лук до мягкости.", "Добавьте морковь и шампиньоны, нарезанные ломтиками, в сковороду и обжарьте до готовности.", "Отдельно варите гречку в соответствии с инструкцией на упаковке.", "В другой сковороде обжарьте курицу, нарезанную небольшими кубиками, до золотистой корочки.", "Соедините обжарку овощей, грибов и курицы в одной сковороде, тщательно перемешайте.", "Перед подачей соедините готовую гречку и обжарку с овощами и курицей, тщательно перемешайте.", "Подавайте гречку с курицей горячей. Приятного аппетита!"],
        "snack2_name": "Греческий салат",
        "snack2_k": "221",
        "snack2_b": "8.6",
        "snack2_j": "17",
        "snack2_u": "8.4",
        "snack2_ingredients": [{
            "name": "Помидор",
            "value": "70 гр"
        }, {
            "name": "Болгарский перец",
            "value": "40 гр"
        }, {
            "name": "Огурец",
            "value": "40 гр"
        }, {
            "name": "Сыр фета",
            "value": "40 гр"
        }, {
            "name": "Маслины крупные",
            "value": "15 гр"
        }, {
            "name": "Лук репчатый",
            "value": "10 гр"
        }, {
            "name": "Масло оливковое",
            "value": "6 гр"
        }, {
            "name": "Чеснок",
            "value": "1 гр"
        }, {
            "name": "Соль, перец, лимонный сок",
            "value": "по вкусу"
        }],
        "snack2_cooking_method": ["Крупно режем помидоры, огурцы кубиками, перец и лук колечками, выкладываем на тарелку.", "Сверху выкладываем маслины, нарезанный кубиками сыр, поливаем оливковым маслом, посыпаем зеленью.", "Салат перемешивать не нужно. Приятного аппетита!"],
        "dinner_name": "Омлет с овощами",
        "dinner_k": "270",
        "dinner_b": "22.2",
        "dinner_j": "16.5",
        "dinner_u": "8.8",
        "dinner_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Яичный белок",
            "value": "1 шт"
        }, {
            "name": "Брокколи",
            "value": "100 гр"
        }, {
            "name": "Спаржевая фасоль",
            "value": "80 гр"
        }, {
            "name": "Масло подсолнечное",
            "value": "4 гр"
        }],
        "dinner_cooking_method": ["На разогретой сковороде с небольшим количеством масла обжарьте брокколи и стручковую фасоль до мягкости.", "Взбейте яйца с яичным белком в миске.", "Положите обжаренные овощи на сковороду и залейте взбитыми яйцами.", "Томите под крышкой на небольшом огне до готовности.", "Подавайте омлет с овощами горячим. Приятного аппетита!"],
        "snack3_name": "Хлебцы с авокадо",
        "snack3_k": "271",
        "snack3_b": "10.1",
        "snack3_j": "16.2",
        "snack3_u": "18.6",
        "snack3_ingredients": [{
            "name": "Рисовые хлебцы",
            "value": "2 шт"
        }, {
            "name": "Яичный белок",
            "value": "2 шт"
        }, {
            "name": "Авокадо",
            "value": "80 гр"
        }, {
            "name": "Соль, специи, лимонный сок",
            "value": "по вкусу"
        }],
        "snack3_cooking_method": ["Размножите мягкое авокадо в миске, используя вилку.", "Добавьте соль, специи и лимонный сок по вкусу, хорошо перемешайте.", "Намажьте полученной массой рисовые хлебцы.", "Отварите 2 яичных белка, нарежьте и выложите на хлебцы или подавайте отдельно. Приятного аппетита!"]
    }, {
        "day_num": "17-18",
        "day_k": "1700.7",
        "day_b": "155.7",
        "day_j": "53.5",
        "day_u": "143",
        "breakfast_name": "Ленивые вареники из кукурузной муки",
        "breakfast_k": "457",
        "breakfast_b": "38.8",
        "breakfast_j": "14.8",
        "breakfast_u": "42.5",
        "breakfast_ingredients": [{
            "name": "Творог",
            "value": "170 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Мука кукурузная",
            "value": "30 гр"
        }, {
            "name": "Мед",
            "value": "20 гр"
        }],
        "breakfast_cooking_method": ["Смешайте творог, яйцо и кукурузную муку, замесите тесто до однородной консистенции.", "Разделите тесто на небольшие кусочки и скатайте их в небольшие шарики.", "Прокипятите воду в кастрюле и добавьте немного соли.", "Берите по несколько шариков теста и аккуратно опускайте их в кипящую воду.", "Варите ленивые вареники около 7 минут или до готовности. Они должны всплыть на поверхность воды.", "С помощью шумовки аккуратно достаньте вареники из кастрюли и выложите на тарелку.", "Полейте ленивые вареники медом.", "Подавайте горячими. Приятного аппетита!"],
        "snack1_name": "Фруктовый салат с творогом",
        "snack1_k": "230",
        "snack1_b": "20.8",
        "snack1_j": "6.5",
        "snack1_u": "23.8",
        "snack1_ingredients": [{
            "name": "Груша",
            "value": "100 гр"
        }, {
            "name": "Яблоко",
            "value": "50 гр"
        }, {
            "name": "Ягоды",
            "value": "50 гр"
        }, {
            "name": "Творог 1%",
            "value": "100 гр"
        }, {
            "name": "Йогурт греческий",
            "value": "50 гр"
        }],
        "snack1_cooking_method": ["Нарежьте грушу, яблоко и ягоды на кубики или дольки, в зависимости от предпочтений.", "Переложите нарезанные фрукты в миску.", "Добавьте творог и греческий йогурт.", "Тщательно перемешайте все ингредиенты, чтобы они равномерно сочетались.", "Подавайте фруктовый салат с творогом и греческим йогуртом. Можно украсить сверху мятой или посыпать медом по вкусу. Приятного аппетита!"],
        "lunch_name": "Паста с курицей в сливочном соусе",
        "lunch_k": "351.3",
        "lunch_b": "29.1",
        "lunch_j": "8.2",
        "lunch_u": "38.5",
        "lunch_ingredients": [{
            "name": "Макароны из ТСП",
            "value": "50 гр"
        }, {
            "name": "Куриное филе",
            "value": "90 гр"
        }, {
            "name": "Петрушка",
            "value": "9 гр"
        }, {
            "name": "Сливки 10%",
            "value": "35 гр"
        }, {
            "name": "Масло подсолнечное",
            "value": "3 гр"
        }, {
            "name": "Специи",
            "value": "по вкусу"
        }],
        "lunch_cooking_method": ["Отварите творожную пасту в соответствии с инструкцией на упаковке.", "Нарежьте куриное филе на мелкие кусочки.", "В сковороде разогрейте масло и обжарьте куриное филе до золотистого цвета.", "Добавьте творожную пасту в сковороду и перемешайте с куриным филе.", "Добавьте сливки и специи по вкусу.", "Подавайте пасту, посыпанную мелко нарезанной петрушкой. Приятного аппетита!"],
        "snack2_name": "Сэндвич с курицей",
        "snack2_k": "202",
        "snack2_b": "24.6",
        "snack2_j": "3.6",
        "snack2_u": "17.4",
        "snack2_ingredients": [{
            "name": "ЦЗ или многозерновой хлеб",
            "value": "2 куска (~40 гр)"
        }, {
            "name": "Куриное филе вареное",
            "value": "70 гр"
        }, {
            "name": "Салат",
            "value": "12 гр (2 листика)"
        }, {
            "name": "Огурец",
            "value": "20 гр"
        }],
        "snack2_cooking_method": ["Подогрейте хлеб на сковороде или в тостере.", "Разложите вареное куриное филе, салат и огурец на одном куске хлеба.", "Накройте вторым куском хлеба.", "Подавайте сэндвич с курицей теплым. Приятного аппетита!"],
        "dinner_name": "Капуста тушеная с курицей",
        "dinner_k": "270",
        "dinner_b": "27",
        "dinner_j": "6.5",
        "dinner_u": "20",
        "dinner_ingredients": [{
            "name": "Капуста белокочанная",
            "value": "260 гр"
        }, {
            "name": "Болгарский перец",
            "value": "65 гр"
        }, {
            "name": "Лук репчатый",
            "value": "45 гр"
        }, {
            "name": "Масло оливковое",
            "value": "1 ложка"
        }, {
            "name": "Чеснок",
            "value": "1 зубчик"
        }, {
            "name": "Вода",
            "value": "100 мл"
        }, {
            "name": "Куриное филе",
            "value": "100 гр"
        }],
        "dinner_cooking_method": ["Все овощи порезать.", "В сковороду или кастрюлю добавить масло и притушить на нем лук и чеснок со специями (кориандр, красный перец). Добавить перец сладкий и филе, через 5 минут добавить капусту.", "Тушить до готовности. При желании можно добавить немного томата или помидоры.", "Если необходимо, прибавить немного жидкости для тушения в зависимости от сочности исходных продуктов.", "В конце добавить паприку и посыпать укропом. Приятного аппетита!"],
        "snack3_name": "Отварные яйца",
        "snack3_k": "190.4",
        "snack3_b": "15.4",
        "snack3_j": "13.9",
        "snack3_u": "0.8",
        "snack3_ingredients": [{
            "name": "Яйцо",
            "value": "3 шт"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "19-20",
        "day_k": "1635.1",
        "day_b": "134.8",
        "day_j": "51.7",
        "day_u": "103.7",
        "breakfast_name": "Омлет с овощами и сыром",
        "breakfast_k": "433.2",
        "breakfast_b": "29.4",
        "breakfast_j": "15.7",
        "breakfast_u": "29.3",
        "breakfast_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Помидор",
            "value": "160 гр"
        }, {
            "name": "Спаржевая фасоль",
            "value": "100 гр"
        }, {
            "name": "Хлеб цельнозерновой",
            "value": "1 ломтик (40 гр)"
        }, {
            "name": "Сыр твердый",
            "value": "30 гр"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "breakfast_cooking_method": ["В миске взбейте яйца вилкой до однородной консистенции.", "Помидоры помойте и нарежьте кубиками. Фасоль стручковую нарежьте на небольшие кусочки.", "В сковороде разогрейте немного масла.", "Вылейте взбитые яйца в сковороду и равномерно распределите по дну.", "Посыпьте сверху нарезанными помидорами и фасолью.", "Посолите, добавьте специи по вкусу.", "Готовьте омлет на среднем огне до готовности яиц, примерно 3-5 минут.", "Осторожно переверните омлет на другую сторону и прожарьте ещё 1-2 минуты.", "Подавайте омлет с помидорками с цельнозерновым хлебом и посыпьте тертым твердым сыром. Приятного аппетита!"],
        "snack1_name": "Салат из яиц и огурцов",
        "snack1_k": "299.7",
        "snack1_b": "26.7",
        "snack1_j": "15.3",
        "snack1_u": "12.4",
        "snack1_ingredients": [{
            "name": "Яйцо",
            "value": "4 шт"
        }, {
            "name": "Огурец",
            "value": "170 гр"
        }, {
            "name": "Йогурт греческий",
            "value": "100 гр"
        }, {
            "name": "Укроп",
            "value": "10 гр"
        }],
        "snack1_cooking_method": ["Отварите 4 яйца и остудите их.", "Отделите белки от желтков. Используйте два яйца целиком (белок + желток) и два яйца без желтка.", "Нарежьте отварные яйца и огурцы на кубики.", "Накройте салат огурцами и яйцами.", "Для заправки смешайте йогурт греческий и натертые желтки.", "Заправьте салат этой смесью и посыпьте мелко нарезанным укропом. Приятного аппетита!"],
        "lunch_name": "Плов с куриной грудкой",
        "lunch_k": "504",
        "lunch_b": "39.9",
        "lunch_j": "9.6",
        "lunch_u": "33.3",
        "lunch_ingredients": [{
            "name": "Рис",
            "value": "40 гр"
        }, {
            "name": "Куриная грудка",
            "value": "100 гр"
        }, {
            "name": "Лук репчатый",
            "value": "25 гр"
        }, {
            "name": "Морковь",
            "value": "35 гр"
        }, {
            "name": "Вода",
            "value": "200 мл"
        }, {
            "name": "Масло подсолнечное",
            "value": "8 гр"
        }],
        "lunch_cooking_method": ["Куриную грудку и лук нарежьте,морковь натрите на крупной терке.", "В сковороде разогрейте масло.", "Добавьте нарезанный лук и обжарьте до золотистого цвета.", "Добавьте нарезанную курицу.", "Добавьте натертую морковь и продолжайте готовить.", "Добавьте рис в сковороду и хорошо перемешайте.", "Добавьте воду, посолите по вкусу и доведите до кипения.", "Уменьшите огонь, накройте сковороду крышкой и варите плов на низком огне около 15-20 минут или до готовности риса. Приятного аппетита!"],
        "snack2_name": "",
        "snack2_k": "",
        "snack2_b": "",
        "snack2_j": "",
        "snack2_u": "",
        "snack2_ingredients": "",
        "snack2_cooking_method": [],
        "dinner_name": "Запеченное куриное филе с салатом из капусты и огурца",
        "dinner_k": "269.2",
        "dinner_b": "36.1",
        "dinner_j": "10.5",
        "dinner_u": "4.4",
        "dinner_ingredients": [{
            "name": "Капуста белокочанная",
            "value": "50 гр"
        }, {
            "name": "Огурец",
            "value": "35 гр"
        }, {
            "name": "Лук репчатый",
            "value": "15 гр"
        }, {
            "name": "Соль",
            "value": "½ ч.л."
        }, {
            "name": "Масло оливковое",
            "value": "1 ст.л"
        }, {
            "name": "Куриное филе",
            "value": "150 гр"
        }, {
            "name": "Специи",
            "value": "по вкусу"
        }],
        "dinner_cooking_method": ["Нарежьте белокочаную капусту , огурец и репчатый лук.", "Добавьте соль и оливковое масло.", "Для запеченного куриного филе: Разогрейте духовку до 200 градусов Цельсия.", "Куриное филе смажьте оливковым маслом и посолите, приправьте специями по своему вкусу.", "Выложите куриное филе на противень, покрытый пергаментной бумагой и Запекайте куриное филе в духовке около 20-25 минут или до готовности. Время запекания может варьироваться. Приятного аппетита!"],
        "snack3_name": "Фрукты/Ягоды",
        "snack3_k": "129",
        "snack3_b": "2.7",
        "snack3_j": "0.6",
        "snack3_u": "24.3",
        "snack3_ingredients": [{
            "name": "Фрукты/Ягоды",
            "value": "300 гр"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "21-22",
        "day_k": "1682",
        "day_b": "165.9",
        "day_j": "53.4",
        "day_u": "138.3",
        "breakfast_name": "Овсяноблин с помидором и сыром",
        "breakfast_k": "405",
        "breakfast_b": "28.9",
        "breakfast_j": "16.8",
        "breakfast_u": "35.9",
        "breakfast_ingredients": [{
            "name": "Овсяные хлопья",
            "value": "50 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Белок",
            "value": "1 шт"
        }, {
            "name": "Сыр 45%",
            "value": "35 гр"
        }, {
            "name": "Помидор",
            "value": "50 гр"
        }, {
            "name": "Соль, перец",
            "value": "по вкусу"
        }],
        "breakfast_cooking_method": ["В миске смешайте овсяные хлопья, яйцо и белок до получения однородной массы.", "Приправьте смесь солью и перцем по вкусу.", "Разогрейте антипригарную сковороду и выпекайте блин из полученного теста с обеих сторон до золотистого цвета.", "Нарежьте помидор и натрите сыр на терке.", "На одну половину готового овсяноблина выложите нарезанный помидор и натертый сыр.", "Сложите блин пополам, чтобы начинка оказалась внутри.", "Подавайте готовый овсяноблин с помидором и сыром, посыпав сверху свежей зеленью. Приятного аппетита!"],
        "snack1_name": "Творог с ягодами,кешью и сметаной",
        "snack1_k": "308.9",
        "snack1_b": "22.9",
        "snack1_j": "14.5",
        "snack1_u": "25.3",
        "snack1_ingredients": [{
            "name": "Кешью",
            "value": "30 гр"
        }, {
            "name": "Творог 1%",
            "value": "100 гр"
        }, {
            "name": "Ягоды",
            "value": "200 гр"
        }, {
            "name": "Сметана 10%",
            "value": "1 ст.л"
        }],
        "snack1_cooking_method": ["В миску положите кешью.", "Добавьте творог и хорошо перемешайте.", "Добавьте свежие ягоды и сметану.", "Перемешайте все ингредиенты, чтобы они равномерно сочетались.", "Готовый Творог с ягодами,кешью и сметаной готов к подаче. Приятного аппетита!"],
        "lunch_name": "Гречка с курицей в чесночном соусе",
        "lunch_k": "350",
        "lunch_b": "35.8",
        "lunch_j": "5.1",
        "lunch_u": "40.1",
        "lunch_ingredients": [{
            "name": "Гречка",
            "value": "50 гр"
        }, {
            "name": "Куриное филе",
            "value": "120 гр"
        }, {
            "name": "Лук репчатый",
            "value": "70 гр"
        }, {
            "name": "Сметана 10%",
            "value": "1 ст.л"
        }, {
            "name": "Чеснок",
            "value": "1 зубчик"
        }],
        "lunch_cooking_method": ["Заранее сварите гречку кипятком, дайте ей настояться под крышкой.", "Нарежьте куриное филе полосками, а лук полукольцами.", "На разогретой сковороде обжарьте курицу с луком до золотистой корочки.", "Размешайте сметану с тертым чесноком, добавьте немного воды, соль и перец по вкусу.", "Залейте обжаренную курицу со сметаной и тушите на среднем огне до готовности.", "Подавайте гречку на тарелку, выложите сверху курицу с соусом. Приятного аппетита!"],
        "snack2_name": "Легкий яичный салат",
        "snack2_k": "102",
        "snack2_b": "16.9",
        "snack2_j": "2.6",
        "snack2_u": "3",
        "snack2_ingredients": [{
            "name": "Отварные белки яиц",
            "value": "4 штуки (~140 гр)"
        }, {
            "name": "Сметана 10%",
            "value": "25 гр"
        }, {
            "name": "Огурец",
            "value": "80 гр"
        }],
        "snack2_cooking_method": ["Нарежьте отварные белки яиц и огурец на мелкие кубики.", "В миске смешайте нарезанные белки и огурец.", "Добавьте сметану и хорошо перемешайте.", "Легкий яичный салат готов к подаче. Приятного аппетита!"],
        "dinner_name": "Салат с тунцом, огурцами и яйцом",
        "dinner_k": "242.6",
        "dinner_b": "27.7",
        "dinner_j": "12.4",
        "dinner_u": "4",
        "dinner_ingredients": [{
            "name": "Тунец в собственном соку",
            "value": "90 гр"
        }, {
            "name": "Руккола",
            "value": "40 гр"
        }, {
            "name": "Огурец",
            "value": "100 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Масло оливковое",
            "value": "5 мл"
        }],
        "dinner_cooking_method": ["В салатнике смешайте рукколу, нарезанный огурец и кусочки тунца в собственном соку.", "Добавьте отварное яйцо.", "Полейте салат оливковым маслом.", "Перемешайте все ингредиенты.", "Готовый салат с тунцом готов к подаче. Приятного аппетита!"],
        "snack3_name": "Творог с черносливом",
        "snack3_k": "273.5",
        "snack3_b": "33.7",
        "snack3_j": "2",
        "snack3_u": "30",
        "snack3_ingredients": [{
            "name": "Творог 1%",
            "value": "200 гр"
        }, {
            "name": "Чернослив",
            "value": "50 гр"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "23-24",
        "day_k": "1628.55",
        "day_b": "183.8",
        "day_j": "26",
        "day_u": "173.6",
        "breakfast_name": "Маффины из овсянки",
        "breakfast_k": "402",
        "breakfast_b": "19.2",
        "breakfast_j": "8.5",
        "breakfast_u": "65",
        "breakfast_ingredients": [{
            "name": "Овсяные хлопья",
            "value": "65 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Белок",
            "value": "1 шт"
        }, {
            "name": "Банан",
            "value": "100 гр"
        }, {
            "name": "Подсластитель",
            "value": "по вкусу"
        }],
        "breakfast_cooking_method": ["Подготовьте формы для запекания, предварительно смазав их маслом или используя бумажные формочки.", "В блендере соедините овсяные хлопья, яйцо, белок, банан и подсластитель. Блендером перемолите все ингредиенты до получения однородной массы.", "Вылейте полученную смесь в подготовленные формы для запекания.", "Разогрейте духовку до 180 градусов и запекайте маффины около 10-15 минут или до готовности. Проверьте готовность, вставив в маффины деревянную шпажку - она должна выйти чистой.", "Подавайте маффины из овсянки теплыми или остывшими. Приятного аппетита!"],
        "snack1_name": "Диетическая шаурма",
        "snack1_k": "299.55",
        "snack1_b": "38.8",
        "snack1_j": "4.4",
        "snack1_u": "25.6",
        "snack1_ingredients": [{
            "name": "Лаваш",
            "value": "40 гр"
        }, {
            "name": "Куриное филе",
            "value": "40 гр"
        }, {
            "name": "Капуста белокочанная",
            "value": "50 гр"
        }, {
            "name": "Огурец соленый",
            "value": "70 гр"
        }, {
            "name": "Творог 2%",
            "value": "70 гр"
        }],
        "snack1_cooking_method": ["Нарежьте отварное филе и соленый огурец на мелкие кусочки.", "Нашинкуйте капусту.", "В миске смешайте нарезанное филе, огурец и капусту.", "Добавьте нежирный творог и хорошо перемешайте.", "Присолите начинку по вкусу.", "Разложите начинку на лаваш и аккуратно заверните.", "Поджарьте лаваш на сухой сковороде до легкой румяной корочки.", "Готовую диетическую шаурму можно нарезать на порционные кусочки и подавать. Приятного аппетита!"],
        "lunch_name": "Булгур с курицей и грибами",
        "lunch_k": "450",
        "lunch_b": "54",
        "lunch_j": "8",
        "lunch_u": "47",
        "lunch_ingredients": [{
            "name": "Куриное филе",
            "value": "170 гр"
        }, {
            "name": "Шампиньоны",
            "value": "100 гр"
        }, {
            "name": "Сметана 15%",
            "value": "30 гр"
        }, {
            "name": "Лук репчатый",
            "value": "40 гр"
        }, {
            "name": "Булгур",
            "value": "50 гр"
        }],
        "lunch_cooking_method": ["Нарежьте лук, куриное филе и шампиньоны пластинами.", "В глубокой сковороде разогрейте масло и обжарьте лук до золотистого цвета.", "Добавьте шампиньоны и обжаривайте их несколько минут, Добавьте курицу, посолите по вкусу и готовьте до полной прожарки курицы.", "Добавьте сметану и немного воды, чтобы получился соус. Перемешайте и готовьте еще несколько минут.", "Отварите булгур,и выложите на тарелку, а сверху распределите курицу с грибами в сметанном соусе. Приятного аппетита!"],
        "snack2_name": "",
        "snack2_k": "",
        "snack2_b": "",
        "snack2_j": "",
        "snack2_u": "",
        "snack2_ingredients": "",
        "snack2_cooking_method": [],
        "dinner_name": "Куриное филе с овощами",
        "dinner_k": "309",
        "dinner_b": "54",
        "dinner_j": "4",
        "dinner_u": "14",
        "dinner_ingredients": [{
            "name": "Куриное филе",
            "value": "200 гр"
        }, {
            "name": "Болгарский перец",
            "value": "100 гр"
        }, {
            "name": "Шампиньоны",
            "value": "100 гр"
        }, {
            "name": "Кабачок",
            "value": "200 гр"
        }],
        "dinner_cooking_method": ["Подготовьте ингредиенты: нарежьте куриное филе, болгарский перец, шампиньоны и кабачок.", "В сковороде разогрейте масло и обжарьте куриное филе до золотистой корочки.", "Добавьте нарезанные овощи и готовьте их до мягкости, периодически помешивая.", "Подавайте куриное филе с овощами. Приятного аппетита!"],
        "snack3_name": "Творог с бананом",
        "snack3_k": "168",
        "snack3_b": "17.8",
        "snack3_j": "1.1",
        "snack3_u": "22",
        "snack3_ingredients": [{
            "name": "Творог",
            "value": "100 гр"
        }, {
            "name": "Банан",
            "value": "100 гр"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "25-26",
        "day_k": "1595.4",
        "day_b": "112.4",
        "day_j": "61.8",
        "day_u": "138.4",
        "breakfast_name": "Тосты с гуакамоле и яйцом пашот",
        "breakfast_k": "415",
        "breakfast_b": "16.4",
        "breakfast_j": "26",
        "breakfast_u": "27",
        "breakfast_ingredients": [{
            "name": "Хлеб ржаной",
            "value": "40 гр"
        }, {
            "name": "Яйцо",
            "value": "2 шт"
        }, {
            "name": "Авокадо",
            "value": "80 гр"
        }, {
            "name": "Лук репчатый",
            "value": "20 гр"
        }, {
            "name": "Помидор",
            "value": "20 гр"
        }, {
            "name": "Сок лимона",
            "value": "1 ч.л"
        }, {
            "name": "Специи",
            "value": "по вкусу"
        }],
        "breakfast_cooking_method": ["Обжарьте ржаной хлеб на сковороде до золотистой корочки.", "Разрежьте авокадо, удалите косточку и выньте мякоть. Разомните мякоть авокадо вилкой в миске.", "Мелко нарежьте лук и помидоры, добавьте их к разомноженному авокадо.", "Приправьте смесь соком лимона, специями, солью и перцем по вкусу. Хорошо перемешайте.", "Приготовьте яйца пашот: в кастрюле на среднем огне доведите воду до кипения. Разбейте яйца в чашку, затем сделайте воде воронку и аккуратно опустите яйца в кипящую воду. Готовьте 3-4 минуты до желаемой степени готовности. Аккуратно достаньте яйца шумовкой.", "Намажьте гуакамоле на ржаной хлеб и сверху положите яйца пашот. Приятного аппетита!"],
        "snack1_name": "Сэндвич со сливочным сыром",
        "snack1_k": "113",
        "snack1_b": "3.7",
        "snack1_j": "4.4",
        "snack1_u": "14.3",
        "snack1_ingredients": [{
            "name": "Хлеб бородинский",
            "value": "1 пласт (~30 гр)"
        }, {
            "name": "Сливочный сыр без добавок",
            "value": "20 гр"
        }, {
            "name": "Огурец",
            "value": "50 гр"
        }],
        "snack1_cooking_method": ["Намажьте сливочный сыр на бородинский хлеб.", "Нарежьте огурец тонкими ломтиками.", "Сложите ломтики огурца на хлеб.", "Готовый бутерброд со сливочным сыром готов к подаче. Приятного аппетита!"],
        "lunch_name": "Рис с рубленными котлетами",
        "lunch_k": "448",
        "lunch_b": "39",
        "lunch_j": "6.6",
        "lunch_u": "55.6",
        "lunch_ingredients": [{
            "name": "Куриное филе",
            "value": "125 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Мука рисовая",
            "value": "20 гр"
        }, {
            "name": "Рис",
            "value": "50 гр"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "lunch_cooking_method": ["Нарежьте куриную грудку на очень мелкие кусочки.", "В большой миске смешайте куриное филе, яйцо, муку рисовую, соль и специи. Хорошо перемешайте, чтобы все ингредиенты хорошо соединились.", "Отдельно сварите рис по инструкции на упаковке.", "Нагрейте сковороду с небольшим количеством масла на среднем огне. Ложкой выложите куриные котлеты на сковороду и обжарьте с обеих сторон до золотистой корочки и полного прожаривания внутри.", "На тарелку выложите сваренный рис и рубленые котлеты. По желанию можно украсить зеленью. Приятного аппетита!"],
        "snack2_name": "Яблоко с бананом",
        "snack2_k": "131",
        "snack2_b": "1.9",
        "snack2_j": "0.9",
        "snack2_u": "30.5",
        "snack2_ingredients": [{
            "name": "Яблоко",
            "value": "100 гр"
        }, {
            "name": "Банан",
            "value": "100 гр"
        }],
        "snack2_cooking_method": [],
        "dinner_name": "Котлеты куриные с творогом и огурцом",
        "dinner_k": "298",
        "dinner_b": "36",
        "dinner_j": "10",
        "dinner_u": "10.2",
        "dinner_ingredients": [{
            "name": "Фарш куриный",
            "value": "150 гр"
        }, {
            "name": "Лук репчатый",
            "value": "20 гр"
        }, {
            "name": "Чеснок",
            "value": "1 зубчик"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Творог 1%",
            "value": "20 гр"
        }, {
            "name": "Огурец",
            "value": "300 гр"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "dinner_cooking_method": ["Подготовьте куриный фарш, измельчив лук и чеснок.", "В большой миске смешайте фарш, лук, чеснок, яйцо, творог, соль и специи. Хорошо перемешайте, чтобы все ингредиенты хорошо соединились.", "Сформируйте котлеты из полученной смеси и выложите их на противень, предварительно покрытый пергаментной бумагой.", "Выпекайте котлеты в предварительно разогретой до 200 градусов печи в течение 40 минут, или до того, как они станут золотистыми и полностью прожаренными внутри.", "Огурец нарежьте тонкими кружками.", "Подавайте котлеты с творогом вместе с огурцом. По желанию можно добавить соус или гарнир. Приятного аппетита!"],
        "snack3_name": "Отварные яйца",
        "snack3_k": "190.4",
        "snack3_b": "15.4",
        "snack3_j": "13.9",
        "snack3_u": "0.8",
        "snack3_ingredients": [{
            "name": "Яйцо",
            "value": "2 шт"
        }],
        "snack3_cooking_method": []
    }, {
        "day_num": "27-28",
        "day_k": "1633",
        "day_b": "132.9",
        "day_j": "60",
        "day_u": "131.4",
        "breakfast_name": "Ленивая ПП-шаурма",
        "breakfast_k": "448",
        "breakfast_b": "38.8",
        "breakfast_j": "14.9",
        "breakfast_u": "39",
        "breakfast_ingredients": [{
            "name": "Лаваш",
            "value": "70 гр"
        }, {
            "name": "Куриное филе",
            "value": "100 гр"
        }, {
            "name": "Авокадо",
            "value": "30 гр"
        }, {
            "name": "Огурец",
            "value": "50 гр"
        }, {
            "name": "Сыр сливочный мягкий",
            "value": "20 гр"
        }],
        "breakfast_cooking_method": ["Нарежьте отварное куриное филе, огурец и авокадо на полосочки.", "Смажьте лаваш сливочным сыром.", "Выложите на лаваш куриное филе, огурец и авокадо.", "Закрутите лаваш, чтобы получился рулет. Приятного аппетита!"],
        "snack1_name": "Хлебцы с авокадо",
        "snack1_k": "271",
        "snack1_b": "10.1",
        "snack1_j": "16.2",
        "snack1_u": "18.6",
        "snack1_ingredients": [{
            "name": "Рисовые хлебцы",
            "value": "2 шт"
        }, {
            "name": "Яичный белок",
            "value": "2 шт"
        }, {
            "name": "Авокадо",
            "value": "80 гр"
        }, {
            "name": "Соль, специи, лимонный сок",
            "value": "по вкусу"
        }],
        "snack1_cooking_method": ["Разомните мягкое авокадо вилкой.", "Добавьте лимонный сок, соль и специи по вкусу, размешайте.", "Смажьте рисовые хлебцы полученной массой.", "Отварите 2 яичных белка.", "Можно нарезать белки и уложить их сверху хлебцев или подавать отдельно. Приятного аппетита!"],
        "lunch_name": "Чечевица с курицей и овощами",
        "lunch_k": "261",
        "lunch_b": "31",
        "lunch_j": "4.5",
        "lunch_u": "22.3",
        "lunch_ingredients": [{
            "name": "Чечевица",
            "value": "25 гр"
        }, {
            "name": "Чеснок",
            "value": "1 зубчик"
        }, {
            "name": "Помидор",
            "value": "75 гр"
        }, {
            "name": "Болгарский перец",
            "value": "50 гр"
        }, {
            "name": "Кабачок",
            "value": "50 гр"
        }, {
            "name": "Лук репчатый",
            "value": "25 гр"
        }, {
            "name": "Масло оливковое",
            "value": "1 ч.л"
        }, {
            "name": "Куриное филе",
            "value": "100 гр"
        }],
        "lunch_cooking_method": ["В кастрюле отварите чечевицу в подсоленной воде до готовности.", "На сковороде разогрейте оливковое масло. Добавьте нарезанный лук, чеснок, кабачок и перец сладкий. Обжарьте овощи до мягкости.", "Добавьте нарезанное куриное филе на сковороду и готовьте до полной прожарки и приготовления курицы.", "Добавьте отваренную чечевицу к овощам и курице.", "Посолите и поперчите по вкусу. Приятного аппетита!"],
        "snack2_name": "Творог с ягодами и зефиром",
        "snack2_k": "236",
        "snack2_b": "18",
        "snack2_j": "2.1",
        "snack2_u": "31.8",
        "snack2_ingredients": [{
            "name": "Зефир",
            "value": "20 гр (половина 1 шт)"
        }, {
            "name": "Ягоды",
            "value": "200 гр"
        }, {
            "name": "Творог 1%",
            "value": "100 гр"
        }],
        "snack2_cooking_method": [],
        "dinner_name": "Белковый блинчик с томатом и сыром",
        "dinner_k": "227",
        "dinner_b": "30.4",
        "dinner_j": "10",
        "dinner_u": "2.5",
        "dinner_ingredients": [{
            "name": "Куриное филе",
            "value": "75 гр"
        }, {
            "name": "Яйцо",
            "value": "1 шт"
        }, {
            "name": "Помидор",
            "value": "50 гр"
        }, {
            "name": "Сыр 30%",
            "value": "15 гр"
        }, {
            "name": "Соль, специи",
            "value": "по вкусу"
        }],
        "dinner_cooking_method": ["В блендере смешайте куриное филе и яйцо до получения однородного теста.", "Разогрейте сковороду на среднем огне и вылейте тесто на сковороду. Разровняйте тесто, чтобы получился равномерный блинчик.", "Через 2 минуты переверните блинчик и сверху выложите нарезанный помидор и посыпьте сыром.", "Продолжайте готовить блинчик до готовности. Убедитесь, что куриное филе полностью прожарено.", "Подавайте белковый блинчик горячим. Приятного аппетита!"],
        "snack3_name": "Овощной салат",
        "snack3_k": "190",
        "snack3_b": "4.6",
        "snack3_j": "12.3",
        "snack3_u": "17.2",
        "snack3_ingredients": [{
            "name": "Огурец",
            "value": "150 гр"
        }, {
            "name": "Болгарский перец",
            "value": "150 гр"
        }, {
            "name": "Помидор",
            "value": "180 гр"
        }, {
            "name": "Масло оливковое",
            "value": "1 ч.л."
        }],
        "snack3_cooking_method": ["Нарежьте огурцы, перец и помидоры на кусочки.", "Полейте овощи оливковым маслом и хорошо перемешайте, чтобы все ингредиенты равномерно смешались.", "Овощной салат готов! Подавайте его как закуску или гарнир к основному блюду. Приятного аппетита!"]
    }];

    const newData = {};
    const select = [];
    const types = {
        breakfast: 'breakfast',
        lunch: 'lunch',
        dinner: 'dinner',
        snack1: 'snack',
        snack2: 'snack',
        snack3: 'snack'
    };

    for (let i of data) {
        for (let j of ['breakfast', 'lunch', 'dinner', 'snack1', 'snack2', 'snack13']) {
            if (!select.includes(i[`${j}_name`]) && i[`${j}_name`]) {
                select.push(i[`${j}_name`]);

                newData[i[`${j}_name`]] = {
                    type: types[j],
                    name: i[`${j}_name`],
                    k: i[`${j}_k`],
                    b: i[`${j}_b`],
                    j: i[`${j}_j`],
                    u: i[`${j}_u`],
                    ingredients: i[`${j}_ingredients`],
                    cooking_method: i[`${j}_cooking_method`]
                };
            }
        }
    }

    console.log(newData);

    fetch("https://demailbuild.dsekon.com/api/registration", {
                method: 'POST'
            })
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["container", _ctx.$style.container])
    }, [
      (_openBlock(), _createBlock(_unref(DField), {
        class: "width-1-1",
        key: _ctx.key
      }, {
        label: _withCtx(() => [
          _createTextVNode(" adsad ")
        ]),
        default: _withCtx(() => [
          _createElementVNode("select", null, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(select, (option) => {
              return _createElementVNode("option", { key: option }, _toDisplayString(option), 1)
            }), 64))
          ])
        ]),
        _: 1
      }))
    ], 2)
  ]))
}
}

}