import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
        ref,
        provide,
        onMounted,
        onBeforeMount
    } from 'vue';
    // import {
    //     useRoute
    // } from 'vue-router';
    import io from 'socket.io-client';

    // const route = useRoute();

    
export default {
  __name: 'App',
  setup(__props) {

    const isActiveProfile = ref(null);
    const navData = ref(null);

    provide('isActiveProfile', isActiveProfile);
    provide('navData', navData);
    // const socket = io('http://dsekon.com:3007/', {
    //     extraHeaders: {
    //         "my-custom-header": "xe"
    //     }
    // }); // Замените на ваш адрес сервера Socket.IO
    // provide('socket', socket);

    // onBeforeMount(() => {
    //     onAuthStateChanged(getAuth(), (user) => {
    //         if (user) {
    //             isActiveProfile.value = true;
    //             // navData.value = ['/denglish', '/letter', '/mycv'];
    //             navData.value = ['/letter', '/mycv'];
    //         } else {
    //             isActiveProfile.value = false;
    //             navData.value = ['/mycv', '/auth'];
    //         }
    //     });
    // });

return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock(_component_router_view))
}
}

}