import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onUpdate:modelValue"]

import {
        ref,
        watch
    } from 'vue';
    import DIcon from '@/Icon';

    
export default {
  __name: 'Tracker',
  setup(__props) {

    let isTraking = ref(false);
    const data = ref([{
        startTime: new Date().getTime(),
        endTime: new Date(new Date().getTime() + 1000000)
    }, {
        startTime: new Date().getTime(),
        endTime: new Date(new Date().getTime() + 1000000)
    }]);
    const startTime = ref(new Date().getTime());
    const endTime = ref(startTime.value);
    let timer;
    const name = ref('');
    const price = ref(null);
    const priceFormatted = ref('');

    function formattedTime(ms) {
        const seconds = Math.floor((ms / 1000) % 60);
        const minutes = Math.floor((ms / (1000 * 60)) % 60);
        const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

        return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    function onPlay() {
        isTraking.value = !isTraking.value;

        if (isTraking.value) {
            startTime.value = new Date().getTime() - 1000;
            endTime.value = new Date().getTime();

            data.value.push({
                startTime: startTime.value
            });

            timer = setInterval(() => {
                endTime.value = new Date().getTime();
            }, 1000);
        } else {
            const dataStartTime = data.value[data.value.length - 1].startTime;
            const dataEndTime = new Date().getTime();
            const dataDifferenceTime = dataEndTime - dataStartTime;
            clearInterval(timer);

            data.value[data.value.length - 1] = {
                startTime: dataStartTime,
                name: name.value,
                endTime: dataEndTime,
                differenceTime: dataDifferenceTime,
                formattedTime: formattedTime(dataDifferenceTime),
                price: price.value,
                summary: (dataDifferenceTime / 60 / 60 / 1000 * price.value).toFixed(2)
            };

            startTime.value = new Date().getTime();
            endTime.value = startTime.value;
            name.value = '';
            price.value = null;
        }
    }

    function formatInput(event) {
        const input = event.target.value.replace(/[^0-9.]/g, "");
        const parsedNumber = parseFloat(input);

        if (!isNaN(parsedNumber)) {
            price.value = parsedNumber;

            priceFormatted.value = price.value.toLocaleString();
        }

        event.target.value = priceFormatted.value;
    }

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style['body'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['content'])
    }, [
      _createElementVNode("header", {
        class: _normalizeClass(_ctx.$style['header'])
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: _normalizeClass(_ctx.$style['header__input']),
          placeholder: "What are you working on?",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((name).value = $event))
        }, null, 2), [
          [_vModelText, name.value]
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: _normalizeClass(_ctx.$style['header__input-price']),
          placeholder: "$",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((priceFormatted).value = $event)),
          onInput: formatInput
        }, null, 34), [
          [_vModelText, priceFormatted.value]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style['header__time'], {[_ctx.$style['active']]: _unref(isTraking)}])
        }, _toDisplayString(formattedTime(endTime.value - startTime.value)), 3),
        _createElementVNode("button", {
          class: _normalizeClass([_ctx.$style['play'], {[_ctx.$style['active']]: _unref(isTraking)}]),
          onClick: onPlay
        }, [
          (!_unref(isTraking))
            ? (_openBlock(), _createBlock(_unref(DIcon), {
                key: 0,
                name: "t-play",
                style: {"margin-left":"3px"}
              }))
            : (_openBlock(), _createBlock(_unref(DIcon), {
                key: 1,
                name: "t-stop"
              }))
        ], 2)
      ], 2),
      (data.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style['tracker-list'])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (item.endTime)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: item,
                      class: _normalizeClass(_ctx.$style['tracker-list__item'])
                    }, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        placeholder: "Add description",
                        class: _normalizeClass(_ctx.$style['tracker-list__item-input']),
                        "onUpdate:modelValue": $event => ((item.name) = $event)
                      }, null, 10, _hoisted_1), [
                        [_vModelText, item.name]
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['tracker-list__item-formatted'])
                      }, _toDisplayString(item.formattedTime), 3),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['tracker-list__item-price'])
                      }, _toDisplayString(item.summary), 3)
                    ], 2))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
}

}