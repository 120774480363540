import { createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import DBtn from '@/Btn';

    
export default {
  __name: 'index',
  props: {
        btnClass: {
            type: String
        }
    },
  setup(__props) {

const props = __props;

    

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/",
    custom: ""
  }, {
    default: _withCtx(({ navigate, href }) => [
      _createVNode(_unref(DBtn), {
        class: _normalizeClass([__props.btnClass, _ctx.$style['logo']]),
        translate: "no",
        settings: {style: 'none'},
        href: href,
        onClick: navigate
      }, {
        default: _withCtx(() => [
          _createTextVNode("DSekon")
        ]),
        _: 2
      }, 1032, ["class", "href", "onClick"])
    ]),
    _: 1
  }))
}
}

}