import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode } from "vue"

import {
        ref,
        watch
    } from 'vue';
    import {
        useEventListener
    } from 'dsrc/assets/js/event';
    import DBtn from '@/Btn';
    import DIcon from '@/Icon';

    
export default {
  __name: 'index',
  props: {
        navData: {
            type: Array
        },
        toggle: {
            type: Function
        },
        isActive: {
            type: Boolean
        }
    },
  setup(__props) {

const props = __props;

    

    const nav = ref(null);
    const navNav = ref(null);

    watch(() => props.isActive, () => {
        nav.value.style.height = props.isActive ? `${navNav.value.clientHeight}px` : '';
    });

    useEventListener(window, 'resize', () => {
        if (window.innerWidth > 767 && props.isActive) {
            nav.value.style.height = '';
        }
    });

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style['nav'], {active: __props.isActive}]),
    ref_key: "nav",
    ref: nav,
    onClick: _cache[0] || (_cache[0] = (...args) => (__props.toggle && __props.toggle(...args)))
  }, [
    _createElementVNode("nav", {
      class: _normalizeClass(_ctx.$style['nav__nav']),
      ref_key: "navNav",
      ref: navNav
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.navData, (navItem) => {
        return _renderSlot(_ctx.$slots, "default", {}, () => [
          _createVNode(_component_router_link, {
            to: navItem,
            custom: ""
          }, {
            default: _withCtx(({ navigate, href, route, isActive }) => [
              _createVNode(_unref(DBtn), {
                class: _normalizeClass({active: isActive}),
                settings: {style: 'link-2'},
                href: href,
                onClick: navigate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(route.name), 1)
                ]),
                _: 2
              }, 1032, ["class", "href", "onClick"])
            ]),
            _: 2
          }, 1032, ["to"])
        ])
      }), 256)),
      _createVNode(_unref(DBtn), {
        settings: {style: 'link-2'},
        href: "https://www.donationalerts.com/r/dsekon",
        target: "_blank"
      }, {
        before: _withCtx(({ parent_class }) => [
          _createVNode(_unref(DIcon), {
            "d-class": parent_class,
            name: "donationalerts"
          }, null, 8, ["d-class"])
        ]),
        default: _withCtx(() => [
          _createTextVNode(" Support ")
        ]),
        _: 1
      })
    ], 2)
  ], 2))
}
}

}