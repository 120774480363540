function propClass(defaultClass, prop, value = 'DSekon') {
    return value !== 'DSekon' ? `${defaultClass}--${prop}_${value}` : false;
}

function childClass(defaultClass, parentClass = 'DSekon') {
    return parentClass !== 'DSekon' && parentClass ? `${parentClass}__${defaultClass.replace('d-', '')}` : false;
}

export {
    propClass,
    childClass
}
