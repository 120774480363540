import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "btn__value"
}

import {
        useAttrs,
        computed
    } from 'vue';
    import {
        propClass
    } from 'dsrc/mixins';

    
export default {
  __name: 'index',
  props: {
        settings: {
            type: Object,
            default: {}
        },
        tag: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        }
    },
  setup(__props) {

const props = __props;

    
    const {
        href
    } = useAttrs();

    const defaultClass = 'btn';
    const getClass = computed(() => {
        return [
            defaultClass,
            propClass(defaultClass, 'style', props.settings.style ?? 'bg'),
            propClass(defaultClass, 'type', props.settings.type)
        ]
    });

return (_ctx, _cache) => {
  const _directive_ripple = _resolveDirective("ripple")

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(!_unref(href) ? (__props.tag ? __props.tag : 'button') : 'a'), {
    class: _normalizeClass(_unref(getClass)),
    type: !_unref(href) ? 'button' : null
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before", _normalizeProps(_guardReactiveProps({parent_class: defaultClass}))),
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "after", _normalizeProps(_guardReactiveProps({parent_class: defaultClass})))
    ]),
    _: 3
  }, 8, ["class", "type"])), [
    [_directive_ripple, !['link', 'link-2', 'none'].includes(props.settings.style) ? true : false]
  ])
}
}

}