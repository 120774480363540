import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

import {
        computed
    } from 'vue'
    import {
        propClass
    } from 'dsrc/mixins';
    import uuidv4 from 'dsrc/assets/js/uuidv4'

    import DTitle from '@/Title'

    
export default {
  __name: 'index',
  props: {
        settings: {
            type: Object,
            default: {}
        },
        label: {
            type: String,
            default: null
        },
        modelValue: [String, Number],
        modelModifiers: {
            default: () => ({})
        }
    },
  setup(__props) {

const props = __props;

    

    const defaultClass = 'd-field';
    const getClass = computed(() => {
        return [
            defaultClass
        ]
    });

    const uid = `${defaultClass}-${uuidv4(true)}`;

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(getClass))
  }, [
    (_ctx.$slots.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(`${defaultClass}__label`),
          for: uid
        }, [
          _renderSlot(_ctx.$slots, "label", _normalizeProps(_guardReactiveProps({parent_class: defaultClass, uid: uid})))
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`${defaultClass}__body`)
    }, [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({parent_class: defaultClass, uid: uid})))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${defaultClass}__message`)
    }, [
      _renderSlot(_ctx.$slots, "under_text")
    ], 2)
  ], 2))
}
}

}