import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
        computed,
        defineAsyncComponent
    } from 'vue';
    import {
        propClass,
        childClass
    } from 'dsrc/mixins';

    
export default {
  __name: 'index',
  props: {
        dClass: {
            type: String,
            default: null
        },
        settings: {
            type: Object,
            default: {}
        },
        name: {
            type: String,
            default: null
        }
    },
  setup(__props) {

const props = __props;

    
    const defaultClass = 'icon';

    const ic = defineAsyncComponent(() => import(`#/${props.name}`));

    const getClass = computed(() => [
        childClass(defaultClass, props.dClass),
        defaultClass,
        propClass(defaultClass, 'orientation', props.settings.orientation)
    ]);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(_unref(getClass))
  }, [
    _createVNode(_unref(ic))
  ], 2))
}
}

}