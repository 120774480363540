import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href", "onClick"]
const _hoisted_5 = ["innerHTML"]

import {
        onMounted
    } from 'vue';
    import DHeader from '&/Header';
    import DFooter from '&/Footer';
    import {
        gsap,
        ScrollTrigger
    } from "gsap/all";

    
export default {
  __name: 'Home',
  setup(__props) {

    gsap.registerPlugin(ScrollTrigger);

    const cards = [{
        title: "<span>D</span>ReadMore - JS plugin for multi-line content truncation with show/hide functionality",
        text: "Read more",
        btn: {
            href: "https://dreadmore.dsekon.com"
        }
    }, {
        title: "<span>D</span>Builder Email - Figma plugin for exporting custom HTML email templates",
        text: "Read more",
        btn: {
            href: "https://e.dsekon.com"
        }
    }];

    function onTest(e) {
        const options = {
            method: "POST",
            headers: {
                accept: "application/json",
                revision: "2022-10-17",
                "content-type": "application/json",
                Authorization: "Klaviyo-API-Key pk_caa8d89f2bfbd9f9633f49f450bfd7600f"
            },
            body: JSON.stringify({
                data: {
                    type: "template",
                    attributes: {
                        name: 'Monthly Newsletter Template',
                        editor_type: "CODE",
                        html: '<html><body>hello world</body></html>'
                    }
                }
            })
        };

        fetch('https://a.klaviyo.com/api/templates/', options)
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(err => console.error(err));
    }


    onMounted(() => {
        if (window.innerWidth > 1024) {
            document.getElementById('Cards').onmousemove = e => {
                for (const card of document.getElementsByClassName('card-test')) {
                    const rect = card.getBoundingClientRect(),
                        x = e.clientX - rect.left,
                        y = e.clientY - rect.top;
                    card.style.setProperty('--mouse-x', `${x}px`);
                    card.style.setProperty('--mouse-y', `${y}px`);
                };
            }
        }
    });

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(DHeader)),
    _createElementVNode("section", {
      id: "Cards",
      class: _normalizeClass(["section", _ctx.$style.section])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style['card-block'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["offset--items_l", _ctx.$style['card-block__list']])
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(cards, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (item.btn.href.startsWith('http'))
                  ? (_openBlock(), _createElementBlock("a", {
                      key: item,
                      class: _normalizeClass(["card-test width-1-2 width-1-1@m", _ctx.$style['card']]),
                      href: item.btn.href,
                      target: "_blank"
                    }, [
                      (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(_ctx.$style['card__content']),
                        key: item
                      }, [
                        _createElementVNode("h3", {
                          class: _normalizeClass(_ctx.$style['card__title']),
                          innerHTML: item.title
                        }, null, 10, _hoisted_3),
                        _createElementVNode("p", {
                          class: _normalizeClass(_ctx.$style['card__text'])
                        }, _toDisplayString(item.text), 3)
                      ], 2))
                    ], 10, _hoisted_2))
                  : (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: item.btn.href,
                      custom: ""
                    }, {
                      default: _withCtx(({ navigate, href }) => [
                        (_openBlock(), _createElementBlock("a", {
                          key: item,
                          class: _normalizeClass(["card-test width-1-2 width-1-1@m", _ctx.$style['card']]),
                          href: href,
                          onClick: navigate
                        }, [
                          (_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass(_ctx.$style['card__content']),
                            key: item
                          }, [
                            _createElementVNode("h3", {
                              class: _normalizeClass(_ctx.$style['card__title']),
                              innerHTML: item.title
                            }, null, 10, _hoisted_5),
                            _createElementVNode("p", {
                              class: _normalizeClass(_ctx.$style['card__text'])
                            }, _toDisplayString(item.text), 3)
                          ], 2))
                        ], 10, _hoisted_4))
                      ]),
                      _: 2
                    }, 1032, ["to"]))
              ], 64))
            }), 64))
          ], 2)
        ], 2)
      ])
    ], 2),
    _createVNode(_unref(DFooter))
  ], 64))
}
}

}